import React, { useRef, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { Localize } from '../../AppLocalization';

// Komponenty
import { Backdrop, Button, CircularProgress, Typography } from '@mui/material';
import MessageBox, { MessageBoxProps } from '../../components/MessageBox';

// Ikony
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';

// Pomôcky: https://github.com/mui-org/material-ui/issues/9716, https://www.youtube.com/watch?v=Ti8QNiRRzOA&ab_channel=ProgrammingWithPrem

interface BackupFileUploadState {
    uploading: boolean;
    progress: number;
}

interface BackupFileUploadProps {
    label?: string;                             // Text tlačidla
    labelIcon?: JSX.Element;                    // Ikona tlačidla
    maxFileSize?: number;                       // Maximálna veľkosť súboru v MB
    accept?: string;                            // Povolené koncovky súborov, napr. ".zip, .jpg" (čiarka a medzera musí byť ako oddelovač)
    onUploaded?: (success: boolean) => void;    // Udalosť, ktorá bude vyvolaná po nahradní súborov
}

const BackupFileUpload = (props: BackupFileUploadProps) => {

    // Jazyková mutácia
    const localization = Localize();

    // Lokálny stav
    const [state, setState] = useState<BackupFileUploadState>({
        uploading: false,
        progress: 0
    });
    const [messageBox, setMessageBox] = useState<MessageBoxProps>({ open: false, title: '', children: null, onClose: () => {
        setMessageBox(prev => ({ 
            ...prev, open: false
        }));
    } });

    // Referencie
    const inputEl = useRef<HTMLInputElement>(null);

    // Automatické nahrávanie súboru po výbere
    const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        
        // Súbory na odoslanie
        const files = e.target.files;
        const filesLength = files?.length ?? 0;

        // Pokračujem len ak som vybral súbory
        if(files == null || filesLength === 0) {
            return;
        }

        // Zobrazím preloader a odošlem formulár
        setState(prev => ({ ...prev, uploading: true, progress: 0 }));

        // Maximálna veľkosť súboru
        const maxFileSize = props.maxFileSize ?? AppConfig.FileUpload.MaxFileSize;

        // Vložím súbory (fotky automaticky zmenším)
        const data = new FormData();
        for(var i = 0; i < filesLength; i++) {
            var file = files[i];
            let fileName = files[i].name;
            let fileSize = 0;

            // Skontrolujem veľkosť súboru
            fileSize = (Math.round(file.size * 100 / (1024 * 1024)) / 100);
            if(fileSize > maxFileSize) {
                setMessageBox(prev => ({
                    ...prev, 
                    open: true,
                    title: localization.couldNotBeUpload,
                    children: localization.maxFileSizeIs + maxFileSize.toFixed(2) + ' MB (' + (fileName ?? '') + ' = ' + (fileSize ?? 0).toFixed(2) + ' MB).'
                }));
                setState(prev => ({ ...prev, uploading: false }));
                return;
            }

            data.append(`images[${i}]`, file);
        }

        axios
            .post(AppConfig.ApiUri + 'backup/upload', data, {
                onUploadProgress: (progress) => {
                    const { loaded, total } = progress;
                    let percent = Math.floor((loaded * 100) / total);
                    setState(prev => ({ ...prev, progress: percent }));
                }
            })
            .then(response => {
                if(response.data !== undefined) {
                    if(props.onUploaded) {
                        props.onUploaded(response.data as boolean);
                    }
                }
            })
            .catch(() => {
                setMessageBox(prev => ({ ...prev, open: true, title: localization.fileUploadFailed, children: localization.couldNotBeUpload }));
            })
            .finally(() => {
                setState(prev => ({ ...prev, uploading: false }));
                if(inputEl && inputEl.current) {
                    inputEl.current.value = '';
                }
            }); 
    };

    return (
        <>
            {/* Upozornenie */}
            <MessageBox {...messageBox} />

            {/* Preloader */}
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={state.uploading}>
                <CircularProgress color="inherit" />
                <Typography ml={2}>{state.progress > 0 ? localization.uploading + ': ' + state.progress.toFixed(0) + '%' : localization.prepareFiles}</Typography>
            </Backdrop>

            {/* FileInput */}
            <input type="file" id="fileupload" onChange={onChange} ref={inputEl} accept={props.accept} hidden multiple />

            {/* Tlačidlo */}
            <label htmlFor="fileupload">
                <Button color="secondary" fullWidth component="span" variant="contained" sx={{ mr: 1 }} startIcon={ props.labelIcon !== undefined ? props.labelIcon : <DriveFolderUploadIcon /> }>
                    { props.label !== undefined ? props.label : localization.insertFile }
                </Button>
            </label>
        </>
    )
}

export default BackupFileUpload;