import { FormEvent, useCallback, useEffect, useState } from 'react'
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { Localize } from '../../AppLocalization';

// Modely
import { Country, Customer } from '../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): Customer => ({
    id: 0,
    name: '',
    address: '',
    countryId: 0,
    email: '',
    phone: '',
    note: ''
});

// Vstupné parametre
export interface CustomerCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    countries: Country[];
    onSave?: () => void;
    onClose: () => void;
}

const CustomerCreate = (props: CustomerCreateProps) => {

    // Jazyková mutácia
    const localization = Localize();

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<Customer>(EmptySource());

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        if ((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'customer/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as Customer);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (source.name === '') {
            setWarning({ ...EmptyWarning, name: 'name', text: localization.mustFill + ': ' + localization.name + '!' });
            return;
        }

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'customer', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: localization.saveFailed });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => input?.focus(), 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    // Otvorenie URL adresy
    const openUrl = (url: string, target?: string) => {
        var link = document.createElement("a");
        link.setAttribute("href", url);
        if (target !== undefined) {
            link.setAttribute("target", target);
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Vyhľadanie adresy, nový email, volať..
    const handleOpenCall = (phone: string) => {
        openUrl('tel:' + phone);
    }
    const handleOpenEmail = (email: string) => {
        openUrl('mailto:' + email);
    }

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="md" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((source?.id ?? 0) === 0 && localization.new + ': ')} {localization.customer}
                        {(props.copy && ' (' + localization.copied + ')')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12}>
                                <TextField required margin="dense" name="name" label={localization.name} fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PersonIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField margin="dense" name="address" label={localization.address} fullWidth variant="outlined" autoComplete="off" value={source.address ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => props.autoFocus === 'address' && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LocationOnIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="source-country">{localization.country}</InputLabel>
                                    <Select labelId="source-country" label={localization.country} value={source.countryId ?? 0} name="countryId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => props.autoFocus === 'countryId' && setFocus(input)}>
                                        <MenuItem key={0} value={0}>-</MenuItem>
                                        {props.countries?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField margin="dense" name="email" label={localization.email} fullWidth variant="outlined" autoComplete="off" value={source.email ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => props.autoFocus === 'email' && setFocus(input)}
                                    inputProps={{ maxLength: 120 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EmailIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField margin="dense" name="phone" label={localization.phone} fullWidth variant="outlined" autoComplete="off" value={source.phone ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => props.autoFocus === 'phone' && setFocus(input)}
                                    inputProps={{ maxLength: 25 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PhoneIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            {((source.phone ?? '').length > 5 || (source.email ?? '').length > 0) && (
                                <Grid item xs={12} mb={1}>
                                    {(source.phone ?? '').length > 5 && <Button variant="outlined" size="small" sx={{ mt: 1, mr: 1 }} onClick={() => handleOpenCall(source.phone ?? '')} startIcon={<PhoneIcon />}>{localization.call}</Button>}
                                    {(source.email ?? '').length > 5 && <Button variant="outlined" size="small" sx={{ mt: 1, mr: 1 }} onClick={() => handleOpenEmail(source.email ?? '')} startIcon={<EmailIcon />}>{localization.email}</Button>}
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <TextField fullWidth multiline rows={4} margin="dense" name="note" label={localization.note} variant="outlined" value={source.note ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => props.autoFocus === 'note' && setFocus(input)}
                                    inputProps={{ maxLength: 1000 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>{localization.reset}</Button>
                        <Button disabled={loading} onClick={props.onClose}>{localization.back}</Button>
                        <Button disabled={loading} type="submit" variant="contained">{localization.save}</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default CustomerCreate;