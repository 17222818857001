import React, { FormEvent, useEffect, useState } from 'react'
import { Localize } from '../../AppLocalization';

// Modely
import { Condition, Customer, Color, Examination, PonyFilter, Trophy, YearLetter, Pony } from '../../models/Models';

// Komponenty
import { Button, TextField, Box, FormControl, InputLabel, MenuItem, Select, Slider, Typography, Autocomplete, CircularProgress, Popper, createFilterOptions } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Sparklines, SparklinesCurve } from 'react-sparklines';

// Ikony
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

// Predpis pre autocomplete
interface AutocompleteOption {
    id: number;
    name: string;
}

interface PoniesFilteringProps {
    ponies: Pony[];
    customers: Customer[];
    yearletters: YearLetter[];
    conditions: Condition[];
    colors: Color[];
    examinations: Examination[];
    trophies: Trophy[];
    filter: PonyFilter;
    heightAtBirthData?: number[]; // Počet je rovný počtu dielokov pre slider, napr. ak je rozsah od 45 do 85, tak bude 40 dielikov s počtom pre danú hodnotu
    heightAtFinalData?: number[]; // Počet je rovný počtu dielokov pre slider, napr. ak je rozsah od 45 do 85, tak bude 40 dielikov s počtom pre danú hodnotu
    onSave: (filter: PonyFilter) => void;
    onReset: () => void;
}

const PoniesFiltering = (props: PoniesFilteringProps) => {

    // Jazyková mutácia
    const localization = Localize();

    // Stav
    const [source, setSource] = useState<PonyFilter>({});

    // Automaticky naplním zdroj
    useEffect(() => { setSource(props.filter); }, [props.filter]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }
    const handleChangeSlider = (property: string, value: number | number[]) => {
        if (typeof value !== 'number') {
            setSource(prev => ({ ...prev, [property + 'From']: value[0] ?? undefined, [property + 'To']: value[1] ?? undefined }));
        }
    }

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        props.onSave(source);
    };

    // Zoradenie položiek, najskôr podľa poradia potom podľa id súboru (poradie môže byť rovnaké)
    const optionsSortComparer = (a: AutocompleteOption, b: AutocompleteOption): number => {
        if ((a.name ?? 0) !== (b.name ?? 0)) {
            return (a.name ?? 0) > (b.name ?? 0) ? 1 : -1;
        }
        else {
            return (a.id ?? 0) > (b.id ?? 0) ? 1 : -1;
        }
    }

    // Rodičia - autocomplete
    const [parentsOpen, setParentsOpen] = useState<boolean>(false);
    const [parentsOptions, setParentsOptions] = useState<AutocompleteOption[]>([]);
    const parentsLoading = parentsOpen && parentsOptions.length === 0;

    // Vynulujem po zavrení
    useEffect(() => {
        if (!parentsOpen) {
            setParentsOptions([]);
        }
    }, [parentsOpen]);

    // Rodičia - naplním po otvorení
    useEffect(() => {
        if (!parentsLoading) {
            return;
        }
        let options: AutocompleteOption[] = [];
        props.ponies.forEach(pony => {
            let name = pony.name + ((pony.dateOfBirthYear ?? 0) > 100 ? ' ' + pony.dateOfBirthLetter + '.' + pony.dateOfBirthYear : '');
            let surfix = '';
            let counter = 0;
            let next = false;

            // Každý názov musí byť unikátny (surfix prikladám až od č. 1)
            while (!next && counter < 100) {
                if (counter > 0) {
                    surfix = ' #' + counter;
                }
                next = (options.find(o => o.name === name + surfix) === undefined);
                counter++;
            }

            // Vložím len ak sa podarilo nájsť unikátny názov
            if (next) {
                options.push(({ 'id': pony.id, 'name': name + surfix }) as AutocompleteOption);
            }
        });

        // Všetky možnosťi musia byť názvom unikátne
        setParentsOptions(options.sort((a, b) => optionsSortComparer(a, b)));
    }, [parentsLoading]);

    // Meno - autocomplete
    const [nameOpen, setNameOpen] = useState<boolean>(false);
    const [nameOptions, setNameOptions] = useState<string[]>([]);
    const [nameInput, setNameInput] = useState<string>('');
    const nameLoading = nameOpen && nameOptions.length === 0;
    const nameFilterOptions = createFilterOptions({
        matchFrom: 'start'
    });

    // Vynulujem po zavrení
    useEffect(() => {
        if (!nameOpen) {
            setNameOptions([]);
        }
    }, [nameOpen]);

    // Rodičia - naplním po otvorení a zmene hodnoty
    useEffect(() => {
        if (!nameLoading) {
            return;
        }
        let options: string[] = [];
        props.ponies.forEach(pony => {
            if ((pony.name?.length ?? 0) > 0 && !options.includes(pony.name ?? '')) {
                options.push(pony.name ?? '');
            }
        });
        // Všetky možnosťi musia byť názvom unikátne
        setNameOptions(options.sort((a, b) => (a < b ? -1 : 1)));
    }, [nameLoading]);

    return (
        <Box component="form" onSubmit={handleSave}>
            <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                <Grid item xs={12}>
                    <Typography variant="body2" textAlign="center" mt={1}>{localization.heightAtBirth} ({source.heightAtBirthFrom ?? '...'} - {source.heightAtBirthTo ?? '...'} cm)</Typography>
                    <Box px={4} mt={1}>
                        <Box minHeight={20}>
                            <Sparklines data={props.heightAtBirthData ?? []} height={20}>
                                <SparklinesCurve color="#999999" />
                            </Sparklines>
                        </Box>
                        <Slider
                            size="small"
                            min={25}
                            max={55}
                            step={1}
                            value={[source.heightAtBirthFrom ?? 25, source.heightAtBirthTo ?? 55]}
                            onChange={(event, value) => handleChangeSlider('heightAtBirth', value)}
                            sx={{ mt: -1 }}
                            valueLabelDisplay="auto"
                            marks={[
                                { value: 25, label: '25 cm' },
                                { value: 35, label: '35 cm' },
                                { value: 45, label: '45 cm' },
                                { value: 55, label: '55 cm' }
                            ]}
                            valueLabelFormat={(value: number) => `${value} cm`}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body2" textAlign="center" mt={1}>{localization.heightAtFinal} ({source.heightAtFinalFrom ?? '...'} - {source.heightAtFinalTo ?? '...'} cm)</Typography>
                    <Box px={4} mt={1}>
                        <Box minHeight={20}>
                            <Sparklines data={props.heightAtFinalData ?? []} height={20}>
                                <SparklinesCurve color="#999999" />
                            </Sparklines>
                        </Box>
                        <Slider
                            size="small"
                            min={55}
                            max={105}
                            step={1}
                            sx={{ mt: -1 }}
                            value={[source.heightAtFinalFrom ?? 55, source.heightAtFinalTo ?? 105]}
                            onChange={(event, value) => handleChangeSlider('heightAtFinal', value)}
                            valueLabelDisplay="auto"
                            marks={[
                                { value: 55, label: '55 cm' },
                                { value: 70, label: '70 cm' },
                                { value: 90, label: '90 cm' },
                                { value: 105, label: '105 cm' },
                            ]}
                            valueLabelFormat={(value: number) => `${value} cm`}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <FormControl margin="dense" variant="outlined" fullWidth>
                        <Autocomplete
                            id="filter-name"
                            open={nameOpen && nameInput.length > 0}
                            value={source.name ?? null}
                            onChange={(s: object, e: any) => {
                                setSource(prev => ({ ...prev, 'name': (e != null ? (e as string) : undefined) }));
                            }}
                            onInputChange={(s, e) => {
                                setNameInput(e);
                            }}
                            onOpen={() => { setNameOpen(true); }}
                            onClose={() => { setNameOpen(false); }}
                            options={nameOptions}
                            loading={nameLoading}
                            filterOptions={nameFilterOptions}
                            renderInput={(params) => (
                                <TextField {...params} size="small" label={localization.name} InputProps={{
                                    ...params.InputProps, endAdornment: (
                                        <React.Fragment>
                                            {nameLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                        </React.Fragment>
                                    ),
                                }}
                                />
                            )}
                            PopperComponent={(props) => <Popper {...props} style={{ maxWidth: "fit-content", minWidth: "250px" }} />}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl margin="dense" variant="outlined" fullWidth>
                        <InputLabel size="small" id="filter-gender">{localization.gender}</InputLabel>
                        <Select size="small" labelId="filter-gender" label={localization.gender} name="genderMale"
                            value={(source.genderMale === undefined ? 0 : source.genderMale === true ? 1 : 2)}
                            onChange={(e) => { handleChange(e.target.name, (e.target.value === 0 ? undefined : (e.target.value === 1))); }}>
                            <MenuItem key={0} value={0}>-</MenuItem>
                            <MenuItem key={1} value={1}>{localization.stallion}</MenuItem>
                            <MenuItem key={2} value={2}>{localization.aMare}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl margin="dense" variant="outlined" fullWidth>
                        <InputLabel size="small" id="filter-year">{localization.yearOfBirth}</InputLabel>
                        <Select size="small" labelId="filter-year" label={localization.yearOfBirth} value={source.yearOfBirth ?? 0} name="yearOfBirth" onChange={(e) => { handleChange(e.target.name, e.target.value) }}>
                            <MenuItem key={0} value={0}>-</MenuItem>
                            {props.yearletters?.map(item => <MenuItem key={item.id} value={item.year}>{item.year} {item.letter}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl margin="dense" variant="outlined" fullWidth>
                        <InputLabel size="small" id="filter-color">{localization.color}</InputLabel>
                        <Select size="small" labelId="filter-color" label={localization.color} value={source.colorId ?? 0} name="colorId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}>
                            <MenuItem key={0} value={0}>-</MenuItem>
                            {props.colors?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl margin="dense" variant="outlined" fullWidth>
                        <InputLabel size="small" id="filter-condition">{localization.condition}</InputLabel>
                        <Select size="small" labelId="filter-condition" label={localization.condition} value={source.conditionId ?? 0} name="conditionId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}>
                            <MenuItem key={0} value={0}>-</MenuItem>
                            {props.conditions?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl margin="dense" variant="outlined" fullWidth>
                        <InputLabel size="small" id="filter-examination">{localization.geneticTests}</InputLabel>
                        <Select size="small" labelId="filter-examination" label={localization.geneticTests} value={source.examinationsId ?? 0} name="examinationsId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}>
                            <MenuItem key={0} value={0}>-</MenuItem>
                            {props.examinations?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl margin="dense" variant="outlined" fullWidth>
                        <InputLabel size="small" id="filter-trophy">{localization.trophiesAndTitles}</InputLabel>
                        <Select size="small" labelId="filter-trophy" label={localization.trophiesAndTitles} value={source.trophyId ?? 0} name="trophyId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}>
                            <MenuItem key={0} value={0}>-</MenuItem>
                            {props.trophies?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl margin="dense" variant="outlined" fullWidth>
                        <InputLabel size="small" id="filter-owner">{localization.owner}</InputLabel>
                        <Select size="small" labelId="filter-owner" label={localization.owner} value={source.customerId ?? 0} name="customerId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}>
                            <MenuItem key={0} value={0}>-</MenuItem>
                            {props.customers?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl margin="dense" variant="outlined" fullWidth>
                        <Autocomplete
                            id="filter-parents"
                            open={parentsOpen}
                            value={((source.parentPonyId ?? 0) > 0 ? { 'id': source.parentPonyId, 'name': source.parentPonyName ?? '' } : parentsOpen ? null : { 'id': 0, 'name': '-' })}
                            onChange={(s: object, e: any) => {
                                setSource(prev => ({
                                    ...prev,
                                    'parentPonyId': (e != null ? (e as AutocompleteOption).id : undefined),
                                    'parentPonyName': (e != null ? (e as AutocompleteOption).name : undefined)
                                }));
                            }}
                            onOpen={() => { setParentsOpen(true); }}
                            onClose={() => { setParentsOpen(false); }}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.name}
                            options={parentsOptions}
                            loading={parentsLoading}
                            renderInput={(params) => (
                                <TextField {...params} size="small" label={localization.production} InputProps={{
                                    ...params.InputProps, endAdornment: (
                                        <React.Fragment>
                                            {parentsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                                />
                            )}
                            PopperComponent={(props) => <Popper {...props} style={{ maxWidth: "fit-content", minWidth: "250px" }} />}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={6} my={1}>
                    <Button onClick={() => props.onReset()} fullWidth variant="contained" color="secondary" startIcon={<ClearIcon />}>{localization.reset}</Button>
                </Grid>

                <Grid item xs={6} my={1}>
                    <Button type="submit" fullWidth variant="contained" startIcon={<SearchIcon />}>{localization.search}</Button>
                </Grid>

            </Grid>
        </Box >
    )
}

export default PoniesFiltering;