import { AppConfig } from './AppConfig';
import { LoadLocalSettings, SaveLocalSettings } from './utility/LocalSettings';

// Vráti aktuálne nastavený jazyk (default podľa AppConfig)
export function GetLanguage(): string {
    return LoadLocalSettings('language', AppConfig.Language);
}

// Uloží vybraný jazyk a obnoví stránku
export function SetLanguage(language: string) {
    if (SaveLocalSettings('language', language, AppConfig.Language)) {
        window.location.reload();
    }
}

// Podporované jazyky
export enum LocalizationLanguage {
    sk = 0,
    en = 1,
    fr = 2
}

// Predpis pre lokalizáciu
export interface Localization {
    ok: string;
    yes: string;
    no: string;
    save: string;
    saved: string;
    saveAs: string;
    saveFailed: string;
    cancel: string;
    reset: string;
    back: string;
    newRecord: string;
    agenda: string;
    statistics: string;
    ponies: string;
    addressBook: string;
    settings: string;
    years: string;
    conditions: string;
    colors: string;
    examinations: string;
    trophiesAndTitles: string;
    trophies: string;
    countries: string;
    enterKeyword: string;
    search: string;
    clean: string;
    created: string;
    updated: string;
    options: string;
    create: string;
    edit: string;
    update: string;
    copy: string;
    copied: string;
    delete: string;
    deleteConfirmation: string;
    deleteSeletedConfirmation: string;
    selected: string;
    cancelSelection: string;
    mustFill: string;
    name: string;
    new: string;
    pony: string;
    compact: string;
    normal: string;
    ordinalNumber: string;
    note: string;
    year: string;
    condition: string;
    color: string;
    examination: string;
    tests: string;
    trophy: string;
    country: string;
    letter: string;
    customer: string;
    address: string;
    email: string;
    phone: string;
    call: string;
    dateOfBirth: string;
    dateOfDead: string;
    heightAtBirth: string;
    heightAtFinal: string;
    male: string;
    female: string;
    sold: string;
    famailyTree: string;
    files: string;
    owner: string;
    notes: string;
    insertFile: string;
    fileUploadFailed: string;
    couldNotBeUpload: string;
    maxFileSizeIs: string;
    prepareFiles: string;
    uploading: string;
    file: string;
    coverImage: string;
    open: string;
    date: string;
    result: string;
    negative: string;
    positive: string;
    carrier: string;
    currentOwner: string;
    yearOfBirth: string;
    positiveExamination: string;
    negativeExamination: string;
    carrierExamination: string;
    gender: string;
    production: string;
    productionReport: string;
    mother: string;
    father: string;
    nameOfCompetition: string;
    stallion: string;
    aMare: string;
    geneticTests: string;
    height: string;
    dead: string;
    preview: string;
    numberOfBirths: string;
    numberOfDeads: string;
    numberOfSold: string;
    print: string;
    backups: string;
    backup: string;
    database: string;
    createBackup: string;
    insertBackup: string;
    createEmptyDatabase: string;
    createEmptyDatabaseConfirm: string;
    areYouSure: string;
    size: string;
    restore: string;
    restoreDatabaseConfirm: string;
    export: string;
    defaultSettings: string;
    informationSheet: string;
    user: string;
    users: string;
    password: string;
    passwordAgain: string;
    passwordNotMatch: string;
    login: string;
    logout: string;
    rememberMe: string;
    doYouForgotPassword: string;
    forgotPassword: string;
    forgotPasswordConfirmSent: string;
    wrongLogin: string;
    loginBlocked: string;
    send: string;
    emailSent: string;
    emailNotSent: string;
    changePassword: string;
    changeLanguage: string;
    alright: string;
    verifying: string;
    exists: string;
}

// Vyplnená jazyková mutácia  s informáciou o použitom jazyku
let localization: Localization | null = null;
let localizationLanguage: LocalizationLanguage | null = null;

export function Localize(): Localization {

    // Predvolený jazyk (ak by sa podľa názvu nenašiel)
    let language: LocalizationLanguage = LocalizationLanguage.en;

    // Nastavený jazyk
    let languageName = GetLanguage();

    // Jazyk podľa nastavení, ak je podporovaný
    if (Object.values(LocalizationLanguage).includes(languageName)) {
        language = LocalizationLanguage[languageName as keyof typeof LocalizationLanguage];
    }

    // Ak je už vyplnená daná jazyková mutácia, tak ju vrátim
    if (localization !== null && localizationLanguage === language) {
        return localization;
    }

    // Vyplním jazykovú mutáciu a vrátim
    localizationLanguage = language;
    localization = {
        ok: [
            'Ok',
            'Ok',
            'Ok'][localizationLanguage],
        yes: [
            'Áno',
            'Yes',
            'Oui'][localizationLanguage],
        no: [
            'Nie',
            'No',
            'Non'][localizationLanguage],
        reset: [
            'Reset',
            'Reset',
            'Réinitialiser'][localizationLanguage],
        save: [
            'Uložiť',
            'Save',
            'Sauvegarder'][localizationLanguage],
        saved: [
            'Uložené!',
            'Saved!',
            'Sauvegardé!'][localizationLanguage],
        saveAs: [
            'Uložiť ako',
            'Save as',
            'Enregistrer sous'][localizationLanguage],
        saveFailed: [
            'Záznam sa nepodarilo uložiť!',
            'Failed to save record!',
            'Échec de la sauvegarde de l\'enregistrement !'][localizationLanguage],
        cancel: [
            'Zrušiť',
            'Cancel',
            'Annuler'][localizationLanguage],
        back: [
            'Späť',
            'Back',
            'Retour'][localizationLanguage],
        newRecord: [
            'Nový záznam',
            'New record',
            'Ajouter'][localizationLanguage],
        agenda: [
            'Agenda',
            'Agenda',
            'Ordre du jour'][localizationLanguage],
        statistics: [
            'Štatistiky',
            'Statistics',
            'Statistiques'][localizationLanguage],
        ponies: [
            'Poníky',
            'Ponies',
            'Poneys'][localizationLanguage],
        addressBook: [
            'Adresár',
            'Address book',
            'Carnet d\'adresses'][localizationLanguage],
        settings: [
            'Nastavenia',
            'Settings',
            'Paramètres'][localizationLanguage],
        years: [
            'Roky',
            'Years',
            'Années'][localizationLanguage],
        conditions: [
            'Stavy',
            'Conditions',
            'Etats'][localizationLanguage],
        colors: [
            'Farby',
            'Colors',
            'Robes'][localizationLanguage],
        examinations: [
            'Vyšetrenia',
            'Examinations',
            'Tests génétiques'][localizationLanguage],
        trophiesAndTitles: [
            'Trofeje a tituly',
            'Trophies and titles',
            'Trophées et titres'][localizationLanguage],
        trophies: [
            'Trofeje',
            'Trophies',
            'Trophées'][localizationLanguage],
        countries: [
            'Krajiny',
            'Countries',
            'Pays'][localizationLanguage],
        enterKeyword: [
            'Vložte kľúčové slovo',
            'Enter a keyword',
            'Entrez un mot-clé'][localizationLanguage],
        search: [
            'Hľadať',
            'Search',
            'Chercher'][localizationLanguage],
        clean: [
            'Vyčistiť',
            'Clean',
            'Nettoyer'][localizationLanguage],
        created: [
            'Vytvorené',
            'Created',
            'Établi'][localizationLanguage],
        updated: [
            'Upravené',
            'Updated',
            'Ajusté'][localizationLanguage],
        options: [
            'Možnosti',
            'Options',
            'Choix'][localizationLanguage],
        create: [
            'Vytvoriť',
            'Create',
            'Créer'][localizationLanguage],
        edit: [
            'Upraviť',
            'Edit',
            'Modifier'][localizationLanguage],
        update: [
            'Aktualizovať',
            'Update',
            'Ajuster'][localizationLanguage],
        copy: [
            'Kopírovať',
            'Copy',
            'Copie'][localizationLanguage],
        copied: [
            'Kópia',
            'Copied',
            'Copié'][localizationLanguage],
        delete: [
            'Vymazať',
            'Delete',
            'Supprimer'][localizationLanguage],
        deleteConfirmation: [
            'Skutočne chcete vymazať tento záznam?',
            'Are you sure you want to delete this record?',
            'Voulez-vous vraiment supprimer cette entrée ?'][localizationLanguage],
        deleteSeletedConfirmation: [
            'Skutočne chcete vymazať vybrané záznamy?',
            'Are you sure you want to delete the selected records?',
            'Voulez-vous vraiment supprimer les enregistrements sélectionnés ?'][localizationLanguage],
        selected: [
            'Vybrané',
            'Selected',
            'Choisi'][localizationLanguage],
        cancelSelection: [
            'Zrušiť výber',
            'Cancel selection',
            'Annuler la sélection'][localizationLanguage],
        mustFill: [
            'Musíte vyplniť',
            'You must fill',
            'Vous devez renseigner'][localizationLanguage],
        name: [
            'Názov',
            'Name',
            'Nom'][localizationLanguage],
        new: [
            'Nový',
            'New',
            'Nouveau'][localizationLanguage],
        pony: [
            'Poník',
            'Pony',
            'Poney'][localizationLanguage],
        compact: [
            'Kompaktné',
            'Compact',
            'Compact'][localizationLanguage],
        normal: [
            'Normálne',
            'Normal',
            'Normal'][localizationLanguage],
        ordinalNumber: [
            'Poradové číslo',
            'Ordinal number',
            'Numéro de série'][localizationLanguage],
        note: [
            'Poznámka',
            'Note',
            'Noter'][localizationLanguage],
        year: [
            'Rok',
            'Year',
            'Année'][localizationLanguage],
        condition: [
            'Stav',
            'Condition',
            'Etat'][localizationLanguage],
        color: [
            'Farba',
            'Color',
            'Robe'][localizationLanguage],
        examination: [
            'Vyšetrenie',
            'Examination',
            'Examen'][localizationLanguage],
        tests: [
            'Vyšetrenia',
            'Tests',
            'Testes'][localizationLanguage],
        trophy: [
            'Trofej, titul',
            'Trophy, titul',
            'Trophée, titre'][localizationLanguage],
        country: [
            'Krajina',
            'Country',
            'Pays'][localizationLanguage],
        letter: [
            'Písmeno',
            'Letter',
            'Lettre'][localizationLanguage],
        customer: [
            'Kontakt',
            'Contact',
            'Contact'][localizationLanguage],
        address: [
            'Adresa',
            'Address',
            'Adresse'][localizationLanguage],
        email: [
            'E-mail',
            'E-mail',
            'E-mail'][localizationLanguage],
        phone: [
            'Telefón',
            'Phone',
            'Téléphoner'][localizationLanguage],
        call: [
            'Volať',
            'Call',
            'Appeler'][localizationLanguage],
        dateOfBirth: [
            'Dátum narodenia',
            'Date of birth',
            'Date de naissance'][localizationLanguage],
        dateOfDead: [
            'Dátum úmrtia',
            'Date of death',
            'Date de décès'][localizationLanguage],
        heightAtBirth: [
            'Výška pri narodení',
            'Height at birth',
            'Taille à la naissance'][localizationLanguage],
        heightAtFinal: [
            'Konečná výška',
            'Final height',
            'Taille adulte'][localizationLanguage],
        male: [
            'Samec',
            'Male',
            'Homme'][localizationLanguage],
        female: [
            'Samica',
            'Female',
            'Femelle'][localizationLanguage],
        sold: [
            'Predaný',
            'Sold',
            'Vendu'][localizationLanguage],
        famailyTree: [
            'Rodokmeň',
            'Family tree',
            'Arbre généalogique'][localizationLanguage],
        files: [
            'Súbory',
            'Files',
            'Photos, documents'][localizationLanguage],
        owner: [
            'Vlastník',
            'Owner',
            'Propriétaire'][localizationLanguage],
        notes: [
            'Poznámky',
            'Notes',
            'Remarques'][localizationLanguage],
        insertFile: [
            'Vložiť súbor',
            'Insert file',
            'Insérer un fichier'][localizationLanguage],
        fileUploadFailed: [
            'Chyba nahrávania',
            'Upload failed',
            'Le téléchargement a échoué'][localizationLanguage],
        couldNotBeUpload: [
            'Súbor nebolo možné nahrať',
            'The file could not be uploaded',
            'Le fichier n\'a pas pu être téléchargé'][localizationLanguage],
        maxFileSizeIs: [
            'Maximálna veľkosť súboru je: ',
            'Maximum file size is: ',
            'La taille maximale du fichier est: '][localizationLanguage],
        prepareFiles: [
            'Príprava súborov',
            'Preparing the files',
            'Préparation des fichiers'][localizationLanguage],
        uploading: [
            'Ukladám',
            'Saving',
            'Enregistrement'][localizationLanguage],
        file: [
            'Súbor',
            'File',
            'Le fichier'][localizationLanguage],
        coverImage: [
            'Titulná fotka',
            'Cover photo',
            'Couverture'][localizationLanguage],
        open: [
            'Otvoriť',
            'Open',
            'Ouvrir'][localizationLanguage],
        date: [
            'Dátum',
            'Date',
            'Date'][localizationLanguage],
        result: [
            'Výsledok',
            'Result',
            'Résultat'][localizationLanguage],
        negative: [
            'Negatívne',
            'Negative',
            'Négatif'][localizationLanguage],
        positive: [
            'Pozitívne',
            'Positive',
            'Positif'][localizationLanguage],
        carrier: [
            'Nositeľ',
            'Carrier',
            'Transporteur'][localizationLanguage],
        currentOwner: [
            'Aktuálny vlastník',
            'Current owner',
            'Propriétaire actuel'][localizationLanguage],
        yearOfBirth: [
            'Rok narodenia',
            'Year of birth',
            'Année de naissance'][localizationLanguage],
        positiveExamination: [
            'Pozitívny na vyšetrenie',
            'Positive to examination',
            'Positif à l\'examen'][localizationLanguage],
        negativeExamination: [
            'Negatívny na vyšetrenie',
            'Nagative to examination',
            'Négatif à l\'examen'][localizationLanguage],
        carrierExamination: [
            'Nositeľ na vyšetrenie',
            'Carrier to examination',
            'Transporteur à l\'examen'][localizationLanguage],
        gender: [
            'Pohlavie',
            'Gender',
            'Sexe'][localizationLanguage],
        production: [
            'Produkcia',
            'Production',
            'Parents'][localizationLanguage],
        productionReport: [
            'Produkcia',
            'Production',
            'Production'][localizationLanguage],
        informationSheet: [
            'Informačný list',
            'Information list',
            'Fiche d\'information'][localizationLanguage],
        mother: [
            'Matka',
            'Mother',
            'Mère'][localizationLanguage],
        father: [
            'Otec',
            'Father',
            'Père'][localizationLanguage],
        nameOfCompetition: [
            'Súťaž, organizácia',
            'Competition, organization',
            'Compétition, organisation'][localizationLanguage],
        stallion: [
            'Žrebec',
            'Stallion',
            'Étalon'][localizationLanguage],
        aMare: [
            'Kobyla',
            'A mare',
            'Jument'][localizationLanguage],
        geneticTests: [
            'Genetické testy',
            'Gentic tests',
            'Testes génétiques'][localizationLanguage],
        height: [
            'Výška',
            'Height',
            'Taille'][localizationLanguage],
        dead: [
            'Mŕtvy',
            'Dead',
            'Morte'][localizationLanguage],
        preview: [
            'Náhľad',
            'Preview',
            'Photo'][localizationLanguage],
        numberOfBirths: [
            'Počet narodení',
            'Number of births',
            'Nombre de naissances'][localizationLanguage],
        numberOfDeads: [
            'Počet úmrtí',
            'Number of deaths',
            'Nombre de décès'][localizationLanguage],
        numberOfSold: [
            'Počet predaných',
            'Number sold',
            'Nombre vendu'][localizationLanguage],
        print: [
            'Vytlačiť',
            'Print',
            'Imprimer'][localizationLanguage],
        backups: [
            'Zálohovanie',
            'Backups',
            'Sauvegardes'][localizationLanguage],
        backup: [
            'Záloha',
            'Backup',
            'Sauvegarde'][localizationLanguage],
        database: [
            'Databáza',
            'Database',
            'Base de données'][localizationLanguage],
        createBackup: [
            'Vytvoriť novú zálohu',
            'Create a new backup',
            'Créer une nouvelle sauvegarde'][localizationLanguage],
        insertBackup: [
            'Vložiť vlastnú zálohu',
            'Insert your own backup',
            'Insérez votre propre sauvegarde'][localizationLanguage],
        createEmptyDatabase: [
            'Vytvoriť prázdnu databázu',
            'Create a empty database',
            'Créer une base de données vide'][localizationLanguage],
        createEmptyDatabaseConfirm: [
            'Skutočne chcete vytvoriť novú prázdnu databázu? Všetky údaje v aktuálnej databáze vrátane súborov budú stratené. Pred vytvorením novej prázdnej databázy sa odporúča vytvoriť zálohu aktuálnej databázy.',
            'Are you sure you want to create a new empty database? All data in the current database, including files, will be lost. Before creating a new empty database, it is recommended to create a backup of the current database.',
            'Voulez-vous vraiment créer une nouvelle base de données vide? Toutes les données de la base de données actuelle, y compris les fichiers, seront perdues. Avant de créer une nouvelle base de données vide, il est recommandé de créer une sauvegarde de la base de données actuelle.'][localizationLanguage],
        areYouSure: [
            'Skutočne ste si istý?',
            'Are you really sure?',
            'Êtes-vous vraiment sûr?'][localizationLanguage],
        size: [
            'Veľkosť',
            'Size',
            'Taille'][localizationLanguage],
        restore: [
            'Obnoviť',
            'Restore',
            'Récupérer'][localizationLanguage],
        restoreDatabaseConfirm: [
            'Skutočne chcete obnoviť databázu zo zálohy? Všetky údaje v aktuálnej databáze vrátane súborov budú stratené. Pred obnovením databázy sa odporúča vytvoriť zálohu aktuálnej databázy.',
            'Are you sure you want to restore the database from a backup? All data in the current database, including files, will be lost. Before restoring the database, it is recommended to create a backup of the current database.',
            'Voulez-vous vraiment restaurer la base de données à partir d\'une sauvegarde? Toutes les données de la base de données actuelle, y compris les fichiers, seront perdues. Avant de restaurer la base de données, il est recommandé de créer une sauvegarde de la base de données actuelle.'][localizationLanguage],
        export: [
            'Exportovať',
            'Export',
            'Exporter'][localizationLanguage],
        defaultSettings: [
            'Predvolené nastavenia',
            'Default settings',
            'Paramètres par défaut'][localizationLanguage],
        user: [
            'Užívateľ',
            'User',
            'Utilisateur'][localizationLanguage],
        users: [
            'Užívateia',
            'Users',
            'Utilisateurs'][localizationLanguage],
        password: [
            'Heslo',
            'Password',
            'Mot de passe'][localizationLanguage],
        passwordAgain: [
            'Heslo znovu',
            'Password again',
            'Mot de passe à nouveau'][localizationLanguage],
        passwordNotMatch: [
            'Heslá sa nezhodujú!',
            'Passwords do not match!',
            'Les mots de passe ne correspondent pas!'][localizationLanguage],
        login: [
            'Prihlásiť',
            'Login',
            'Connectez-vous'][localizationLanguage],
        logout: [
            'Odhlásiť',
            'Logout',
            'Déconnexion'][localizationLanguage],
        rememberMe: [
            'Zostať trvale prihlásený',
            'Stay permanently logged in',
            'Restez connecté en permanence'][localizationLanguage],
        doYouForgotPassword: [
            'Zabudli ste heslo?',
            'Forgot your password?',
            'Mot de passe oublié?'][localizationLanguage],
        forgotPassword: [
            'Zabudnuté heslo',
            'Forgot password',
            'Mot de passe oublié'][localizationLanguage],
        forgotPasswordConfirmSent: [
            'Na Vašu emailovú adresu bude odoslaný overovací email, pomocou ktorého bude možné vygenerovať nové heslo.',
            'A verification email will be sent to your email address, with which a new password can be generated.',
            'Un e-mail de vérification sera envoyé à votre adresse e-mail, avec lequel un nouveau mot de passe peut être généré.'][localizationLanguage],
        wrongLogin: [
            'Nesprávne prístupové údaje!',
            'Incorrect access data!',
            'Données d\'accès erronées!'][localizationLanguage],
        loginBlocked: [
            'Prihlásenie je na niekoľko minút zablokované!',
            'Login is blocked for a few minutes!',
            'La connexion est bloquée pendant quelques minutes!'][localizationLanguage],
        send: [
            'Odoslať',
            'Send',
            'Envoyer'][localizationLanguage],
        emailSent: [
            'Email bol úspešne odoslaný!',
            'The email was successfully sent!',
            'L\'e-mail a été envoyé avec succès !'][localizationLanguage],
        emailNotSent: [
            'Email sa nepodarilo odoslať!',
            'Email could not be sent!',
            'Le mail n\'a pas pu être envoyé!'][localizationLanguage],
        changePassword: [
            'Zmeniť heslo',
            'Change password',
            'Changer le mot de passe'][localizationLanguage],
        changeLanguage: [
            'Zmeniť jazyk',
            'Change langauge',
            'Changer de langue'][localizationLanguage],
        alright: [
            'V poriadku',
            'Alright',
            'Bien'][localizationLanguage],
        verifying: [
            'Overujem',
            'Verifying',
            'Vérifie'][localizationLanguage],
        exists: [
            'Existuje',
            'Exists',
            'Existe'][localizationLanguage],
        /*
        : [
            '',
            '',
            ''][localizationLanguage],
        */

    };
    return localization;
};