import { Localize } from './AppLocalization';

// Podstránky
import Ponies from './views/pony/Ponies';
import Customers from './views/customer/Customers';
import Conditions from './views/condition/Conditions';
import Colors from './views/color/Colors';
import YearLetters from './views/yearletter/YearLetters';
import Examinations from './views/examination/Examinations';
import Trophies from './views/trophy/Trophies';
import Countries from './views/country/Countries';
import Statistics from './views/statistics/Statistics';
import Users from './views/user/Users';
import Backups from './views/backup/Backups';

// Pomôcky: 
// https://betterprogramming.pub/the-best-way-to-manage-routes-in-a-react-project-with-typescript-c4e8d4422d64
// https://www.youtube.com/watch?v=J6jzDfHoj-Y&ab_channel=TheNerdyCanuck

// Zoznam všetkých URL adries
export enum AppRouteUrl {
    STATISTICS = '/',
    PONIES = '/ponies/',
    CUSTOMERS = '/customers/',
    YEARS = '/years/',
    CONDITIONS = '/conditions/',
    COLORS = '/colors/',
    EXAMINATIONS = '/examinations/',
    TROPHIES = '/trophies/',
    COUNTRIES = '/countries/',
    USERS = '/users/',
    BACKUPS = '/backups/'
};

// Zoznam všetkých podstránok
export function GetRoutes(): AppRouteType[] {

    // Jazyková mutácia
    const localization = Localize();
 
    return [
        { page: AppRouteUrl.STATISTICS, title: localization.statistics, component: Statistics, exact: true },
        { page: AppRouteUrl.PONIES, title: localization.ponies, component: Ponies, exact: true },
        { page: AppRouteUrl.CUSTOMERS, title: localization.addressBook, component: Customers, exact: true },
        { page: AppRouteUrl.YEARS, title: localization.years, component: YearLetters, exact: true },
        { page: AppRouteUrl.CONDITIONS, title: localization.conditions, component: Conditions, exact: true },
        { page: AppRouteUrl.COLORS, title: localization.colors, component: Colors, exact: true },
        { page: AppRouteUrl.EXAMINATIONS, title: localization.examinations, component: Examinations, exact: true },
        { page: AppRouteUrl.TROPHIES, title: localization.trophiesAndTitles, component: Trophies, exact: true },
        { page: AppRouteUrl.COUNTRIES, title: localization.countries, component: Countries, exact: true },
        { page: AppRouteUrl.USERS, title: localization.users, component: Users, exact: true },
        { page: AppRouteUrl.BACKUPS, title: localization.backups, component: Backups, exact: true }
    ];
}

// Predpis pre podstránku 
interface AppRouteType {
    page: AppRouteUrl;
    title: string;
    exact: boolean;
    component: any;
    props?: any;
};