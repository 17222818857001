
export const ConvertToDecimal = (value: any) : number | undefined => {
    var result: number | undefined = 0;
    try {
        result = parseFloat((value.toString() ?? '').replace(/[,]/g, '.').replace(/[^\d.-]/g, ''));
    }
    catch (e) { result = 0; }
    if(isNaN(result)) {
        result = undefined;
    }
    return result;  
};

export const ConvertToInt = (value: any) : number | undefined => {
    var result: number | undefined = 0;
    try {
        result = parseInt((value.toString() ?? '').replace(/[^\d-]/g, ''));
    }
    catch (e) { result = 0; }
    if(isNaN(result)) {
        result = undefined;
    }
    return result;  
};
