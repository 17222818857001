import React, { useState } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Alert, Backdrop, CircularProgress, Grid, ListItemIcon, Menu, MenuItem } from '@mui/material';
import Logo from '../../assets/logo.png';
import InputAdornment from '@mui/material/InputAdornment';
import { CookieCreate } from '../../utility/Cookies';
import { AppConfig } from '../../AppConfig';
import PasswordRecovery from './PasswordRecovery';
import { Localize, SetLanguage } from '../../AppLocalization';

// Modely
import { Authorization } from '../../models/Models';

// Ikony
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import FlagIcon from '@mui/icons-material/Flag';

interface Props {
    onLogged: () => void;
}

const Login = (props: Props) => {

    // Jazyková mutácia
    const localization = Localize();

    // Elementy
    const [anchorLanguageEl, setAnchorLanguageEl] = useState<null | HTMLElement>(null);

    // Stav
    const [loading, setLoading] = React.useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [loggedState, setLoggedState] = useState(0);
    const [passwordRecoveryOpen, setPasswordRecoveryOpen] = useState(false);
    const [remember, setRemember] = useState<boolean>(true);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (loading) {
            return;
        }
        setLoading(true);
        setLoggedState(0);

        const data = new FormData(event.currentTarget);
        const email = data.get('email')?.toString() ?? '';
        const emailIsValid = /\S+@\S+\.\S+/.test(email);
        const password = data.get('password')?.toString() ?? '';
        const passwordIsValid = password.length > 0;
        const remember = ((data.get('remember')?.toString() ?? 'false') === 'true');

        setEmailError(!emailIsValid);
        setPasswordError(!passwordIsValid)

        if (!emailIsValid || !passwordIsValid) {
            setLoading(false);
            return;
        }

        axios
            .post(AppConfig.ApiUri + 'user/login',
                {
                    email: email,
                    password: password,
                    remember: remember
                } as Authorization
            )
            .then(response => {
                if (response.data.message === 'error') {
                    setLoading(false);
                    setLoggedState(1);
                    return;
                }
                if (response.data.message === 'blacklist') {
                    setLoading(false);
                    setLoggedState(2);
                    return;
                }
                if (response.data.session !== '') {
                    CookieCreate(AppConfig.CookieName.LoggedSession, response.data.session, 1);
                }
                if (response.data.sessionRemember !== '') {
                    CookieCreate(AppConfig.CookieName.LoggedSessionRemember, response.data.sessionRemember, 365);
                }
                props.onLogged();
                setLoading(false);
            });
    };

    return (
        <Container component="main" maxWidth="sm">
            <Box
                sx={{
                    marginTop: 8,
                    marginBottom: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <img alt="" src={Logo} style={{ marginBottom: '20px', maxWidth: '250px', width: '70%' }} />
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <Grid container columnSpacing={1}>

                        <Grid item xs={12}>
                            <TextField margin="normal" error={emailError} required fullWidth label={localization.email} name="email" autoComplete="email" autoFocus
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <EmailOutlinedIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField margin="normal" error={passwordError} required fullWidth name="password" label={localization.password} type="password" autoComplete="current-password"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <LockOutlinedIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel control={<Checkbox size="small" value={remember} onChange={(e) => { setRemember(e.target.checked); }} name="remember" color="primary" />} checked={remember} label={localization.rememberMe} />
                        </Grid>

                        <Grid item xs={12}>
                            <Button type="submit" disabled={loading} sx={{ mt: 1, mb: 1 }} fullWidth size="large" variant="contained">{localization.login}</Button>
                            {(loggedState === 1 && <Alert sx={{ mb: 1 }} severity="warning">{localization.wrongLogin}</Alert>)}
                            {(loggedState === 2 && <Alert sx={{ mb: 1 }} severity="error">{localization.loginBlocked}</Alert>)}
                        </Grid>

                        <Grid item xs={6} sx={{ mt: 1 }}>
                            <Link href="#" onClick={() => { setPasswordRecoveryOpen(true) }}>{localization.doYouForgotPassword}</Link><br />
                        </Grid>
                        <Grid item xs={6} sx={{ mt: 1, textAlign: 'right' }}>
                            <Link href="#" aria-controls="menu-language" aria-haspopup="true" onClick={(e) => setAnchorLanguageEl(e.currentTarget)}>{localization.changeLanguage}</Link>
                        </Grid>

                        <Menu id="menu-language" anchorEl={anchorLanguageEl} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} keepMounted transformOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={Boolean(anchorLanguageEl)} onClose={() => setAnchorLanguageEl(null)} >
                            <MenuItem dense onClick={() => { SetLanguage('fr'); }}>
                                <ListItemIcon><FlagIcon fontSize="small" /></ListItemIcon> Français
                            </MenuItem>
                            <MenuItem dense onClick={() => { SetLanguage('en'); }}>
                                <ListItemIcon><FlagIcon fontSize="small" /></ListItemIcon> English
                            </MenuItem>
                            <MenuItem dense onClick={() => { SetLanguage('sk'); }}>
                                <ListItemIcon><FlagIcon fontSize="small" /></ListItemIcon> Slovenčina
                            </MenuItem>
                        </Menu>

                    </Grid>
                </Box>
                {(passwordRecoveryOpen && <PasswordRecovery onClickToggle={() => { setPasswordRecoveryOpen(!passwordRecoveryOpen) }} />)}
            </Box>
        </Container>
    )
}

export default Login;