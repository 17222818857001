import { useEffect, useState } from 'react';
import { Box, Container, Drawer, useTheme } from '@mui/material';
import Header from './Header';
import Sidebar from './Sidebar';

// Utility
import { ResponsivePoint as HidePoint } from '../utility/ResponsivePoint';
import { LoadLocalSettings, SaveLocalSettings } from '../utility/LocalSettings';

// Ikony a assety
import logo from '../assets/logo.png';
import logoIcon from '../assets/logo-icon.svg';

interface Props {
    children: any;
    window?: () => Window;
}

const Layout = (props: Props) => {
    const theme = useTheme();
    const { window } = props;
    const container = window !== undefined ? () => window().document.body : undefined;
    const isMobile = HidePoint().sm;

    useEffect(() => {
        if (isMobile) {
            setOpen(false);
        }
    }, [isMobile]);
    
    const [open, setOpen] = useState(LoadLocalSettings('sidebar-open', 'true') === 'true');
    const setOpenAndSave = (value: boolean) => {
        setOpen(value);
        SaveLocalSettings('sidebar-open', value.toString());
    };

    return (
        <Box
            sx={{
                display: 'grid', position: 'fixed', width: '100%', height: '100%',
                gridTemplateColumns: {
                    md: (!open ? '60px auto' : '240px auto'),
                    xs: '0px auto'
                },
                gridTemplateRows: 'auto',
                gridTemplateAreas: '"sidebar content"'
            }}
        >
            <Drawer container={container} variant="temporary" open={open} onClose={() => setOpen(!open)} ModalProps={{ keepMounted: false }}
                sx={{
                    display: { xs: 'block', md: 'none' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '240px' },
                }}>
                <div style={{ textAlign: 'center', margin: '30px 0 10px 0' }}>
                    <img alt="" src={logo} style={{ width: '60%' }} />
                </div>
                <Sidebar open={true} onClickToggle={() => setOpen(false)} />
            </Drawer>

            <Box
                sx={{
                    gridArea: 'sidebar', overflowX: 'hidden', overflowY: 'auto', backgroundColor: theme.layout.sidebar.backgroundColor, borderRightWidth: '1px',
                    borderRightColor: theme.layout.sidebar.borderColor, borderRightStyle: 'solid'
                }}>
                {open ? <div style={{ textAlign: 'center', margin: '40px 0 20px 0' }}>
                    <img alt="" src={logo} style={{ width: '80%' }} />
                </div>
                    : <div style={{ textAlign: 'center', margin: '15px 0 10px 0' }}>
                        <img alt="" src={logoIcon} style={{ width: '60%' }} />
                    </div>}
                <Sidebar open={open} />
            </Box>

            <Box sx={{ gridArea: 'content', overflowY: 'auto', position: 'relative' }}>
                <Header onToggleSidebar={() => setOpenAndSave(!open)} />
                <Container maxWidth={false} sx={{ 
                        position: 'absolute', height: '100%', boxSizing: 'border-box',
                        padding: { xs: '90px 10px 10px 10px', md: '90px 20px 20px 20px' } 
                    }}>
                    {props.children}
                </Container>
            </Box>

        </Box>
    )
}

export default Layout;
