import { EscapeRegExp, RemoveAccents } from "./Text";

export const FindText = (items: any[], search: string, fieldNames?: string[]): any[] => {
  // Z hľadaného textu vytvorim regulárny výraz (odstránim diakritiku a zmením na malé písmená)
  const searchRegex = new RegExp(EscapeRegExp(RemoveAccents(search.trim().toLowerCase())), 'i');

  // Slučka, ktorá bude rekurzívne prechádzať parametre až kým nenájde prvú zhodu
  const searchLoop = (item: any): boolean => {
    return Object.keys(item).some((field: any) => {
      if (typeof item[field] === 'object') {
        return searchLoop(item[field]);
      }
      else {
        // Ak sú na vstupe názvy polí, tak budem hľadať zhodu iba vo vybraných poliach 
        return (fieldNames === undefined || fieldNames?.includes(field)) && searchRegex.test(RemoveAccents(item[field].toString().toLowerCase()));
      }
    });
  };

  // Odfiltrujem záznamy, ktoré zodpovedajú filtru
  return items.filter((item: any) => searchLoop(item));
};
