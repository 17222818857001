import { useState, ChangeEvent } from 'react';
import { Localize } from '../AppLocalization';

// Utility
import Debounce from '../utility/Debounce';

// Komponenty
import { Badge, Divider, InputBase, Paper } from '@mui/material';

// Ikony
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

interface SearchProps {
    delay?: number;
    autoFocus?: boolean;
    filter?: boolean;
    filterCount?: number;
    onSearch: (s: string) => void;
    onFilter?: () => void;
    onClear: () => void;
}

const Search = (props: SearchProps) => {

    // Jazyková mutácia
    const localization = Localize();

    const [searchText, setSearchText] = useState('');
    
    const handleSearch = Debounce((s: string) => {
        props.onSearch(s);
    }, props.delay ?? 500);

    const handleChange = (e:ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        handleSearch(e.target.value);
    };

    const handleClear = () => {
        setSearchText('');
        props.onClear();
    };

    return (
        <Paper sx={{ p: '2px 4px 2px 0px', display: 'inline-flex', alignItems: 'center', width: '100%' }} 
            onKeyUp={(e: any) => {
                if(e.code === 'Enter') {
                    props.onSearch(searchText);
                }
                if(e.code === 'Escape') {
                    handleClear();
                }
            }}>
            <InputBase
                value={searchText}
                sx={{ ml: 1, pl: 1, flex: 1 }}
                placeholder={localization.enterKeyword}
                inputProps={{ 'aria-label': localization.enterKeyword }}
                onChange={handleChange} 
                autoFocus={props.autoFocus}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label={localization.search} title={localization.search} onClick={() => props.onSearch(searchText)}>
                <SearchIcon />
            </IconButton>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            {(props.filter ?? false) === true && (
                <>
                    <IconButton type="button" sx={{ p: '10px' }} onClick={props.onFilter}>
                        <Badge badgeContent={props.filterCount} color="primary">
                            {(props.filterCount ?? 0) === 0 ? <FilterAltOutlinedIcon /> : <FilterAltIcon />}
                        </Badge>             
                    </IconButton>
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                </>
            )}
            <IconButton sx={{ p: '10px' }} aria-label={localization.clean} title={localization.clean} onClick={handleClear}>
                <ClearIcon />
            </IconButton>
        </Paper>
    )
}

export default Search
