import React, { FormEvent, useEffect, useState } from 'react';
import { GetLanguage, Localize } from '../../AppLocalization';

// Komponenty
import { Dialog, Box, DialogTitle, DialogContent, Grid, TextField, Alert, InputAdornment, AlertColor, DialogActions, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import skLocale from 'date-fns/locale/sk';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr';

// Utility
import { nullMinDate } from '../../utility/Date';

// Modely
import { Examination, PonyExamination } from '../../models/Models';

// Ikony
import EditIcon from '@mui/icons-material/Edit';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): PonyExamination => ({
    id: 0,
    date: new Date(),
    note: ''
});

// Vstupné parametre
export interface PonyCreateExaminationProps {
    examination?: PonyExamination;
    examinationIndex?: number;
    examinations: Examination[];
    open: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave: (examination: PonyExamination, examinationIndex?: number) => void;
    onClose: () => void;
}

const PonyCreateExamination = (props: PonyCreateExaminationProps) => {

    // Jazyková mutácia
    const localization = Localize();

    // Stav
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<PonyExamination>(EmptySource());

    // Automaticky vyplním okno
    useEffect(() => {
        if (!props.open) {
            return;
        }
        // Použijem props.item alebo emptySource
        var data = props.examination ?? { ...EmptySource() };
        data.date = nullMinDate(data.date) ?? undefined;
        setSource(data);
    }, [props.examination, props.open]); // eslint-disable-line react-hooks/exhaustive-deps

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => input?.focus(), 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    // Reset formuláru (resetujem iba tie hodnoty, ktoré viem upraviť cez tento formulár)
    const handleReset = () => {
        setSource(prev => ({
            ...prev,
            examinationId: undefined,
            result: undefined,
            date: new Date(),
            note: ''
        }))
    };

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        var data = { ...source };
        data.date = nullMinDate(data.date) ?? undefined;

        if ((data.examinationId ?? 0) === 0) {
            setWarning({ ...EmptyWarning, name: 'examination', text: localization.mustFill + ': ' + localization.examination + '!' });
            return;
        }

        props.onSave(data, props.examinationIndex);
        props.onClose();
    };

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Vráti jazykovú mutáciu pre datepicker
    const dateLocale = (): Locale => {
        return GetLanguage() === 'en' ? enLocale :
            GetLanguage() === 'fr' ? frLocale :
                skLocale;
    };

    return (
        <>
            <Dialog maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick' && props.onClose) { props.onClose(); } }}>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {props.examinationIndex === undefined && localization.new + ': '} {localization.examination}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }} alignItems="flex-end">

                            <Grid item xs={12} sm={8}>
                                <FormControl required margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="source-examination">{localization.examination}</InputLabel>
                                    <Select required labelId="source-examination" label={localization.examination} value={source.examinationId ?? ''} name="examinationId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => props.autoFocus === 'examinationId' && setFocus(input)}>
                                        {props.examinations?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={dateLocale()}>
                                    <DatePicker inputFormat='dd.MM.yyyy' disableMaskedInput={false}
                                        renderInput={(props: any) => <TextField required fullWidth margin="dense" autoComplete="off" variant="outlined" {...props} />} label={localization.date} value={source.date ?? null} onChange={(d: any) => { handleChange('date', d) }}
                                        inputRef={(input: any) => props.autoFocus === 'date' && setFocus(input)} />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField fullWidth multiline rows={4} margin="dense" name="note" label={localization.note} variant="outlined" value={source.note ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => props.autoFocus === 'note' && setFocus(input)}
                                    inputProps={{ maxLength: 1000 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                        </Grid>
                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleReset} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>{localization.reset}</Button>
                        <Button onClick={props.onClose}>{localization.back}</Button>
                        <Button type="submit" variant="contained">{localization.save}</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default PonyCreateExamination;