import { Localize } from '../../AppLocalization';

// Modely
import { Pony } from '../../models/Models';

// Komponenty
import { Box, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import PonyTree from './PonyTree';

// Vstupné parametre
export interface PonyTreeDialogProps {
    pony: Pony;         // Root
    ponies: Pony[];     // All
    levels: number;     // Počet úrovní
    open: boolean;
    keepMounted?: boolean;
    onClose: () => void;
}

const PonyTreeDialog = (props: PonyTreeDialogProps) => {

    // Jazyková mutácia
    const localization = Localize();

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="xl" fullWidth open={props.open} scroll="paper" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <DialogContent dividers={true} sx={{ p: 1, height: '100vh' }}>
                    <Box sx={{ height: '100%' }}>
                        <PonyTree ponies={props.ponies} pony={props.pony} levels={props.levels} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} type="submit" variant="contained">{localization.back}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default PonyTreeDialog;