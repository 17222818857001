// Komponenty
import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';

interface Props {
    children: any;
}

export const Content = (props: Props) => {
    const history = useHistory();

    // Funkcia pre zmenu url z externého javascriptu
    // Táto funkcia musí byť vložená do komponentu, ktorý je vložený až pod komponentom "Switch", preto nemôže byť v komponente "Layout"
    // Komponent "Content" je použitý na každej podstránke, takže je použiteľný všade, ak by nebol, stačilo byť vloži pod "Switch" čistý komponent s touto funkciou
    // @ts-ignore
    window.externalHistoryPush = (url) => {
        history.push(url);
    };

    return (
        <Box sx={{
            display: 'grid', width: '100%', height: '100%',
            gridTemplateAreas: ' "top" "bottom" ',
            gridTemplateRows: {
                xs: '105px auto',
                md: '60px auto'
            }
        }}>
            {props.children}
        </Box>
    )
}

export const ContentTop = (props: Props) => {
    return (
        <Box sx={{ gridArea: 'top' }}>
            {props.children}
        </Box>
    )
}

export const ContentBottom = (props: Props) => {
    return (
        <Box sx={{ gridArea: 'bottom' }}>
            {props.children}
        </Box>
    )
}