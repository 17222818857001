import { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';

// Utility
import { FileOpen } from '../../utility/File';
import { CookieRead } from '../../utility/Cookies';

// Komponenty
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import Iframe from '../../components/Iframe';
import { GetLanguage, Localize } from '../../AppLocalization';

export interface ReportDocumentProps {
    documentId: number;         // id dokladu
    documentTypeId: number;     // typ dokladu pre zoznam šablón (ak typ = 0, ide rovno o objednávku, ak typ > 0, ide o doklad vychádzajúci z objednávky)
    open: boolean;
    preview?: boolean;          // true = doklad sa zobrazí cez iframe s automatickou tlačou, false = doklad sa otvori v novom okne
    format?: string;            // html, pdf
    print?: boolean;
    onClose: () => void;
}

const ReportDocument = (props: ReportDocumentProps) => {
    const localization = Localize();

    // Náhľad dokladu
    const [previewSrc, setPreviewSrc] = useState<string>('');

    // Automaticky zbalím tlačidlá po otvorení okna
    useEffect(() => {
        if (!props.open) {
            return;
        }

        // Zložím URL pre tlač
        const printUrl = AppConfig.ApiUri + 'report/document'
            + '?documentId=' + props.documentId
            + '&documentTypeId=' + props.documentTypeId
            + '&language=' + GetLanguage()
            + '&format=' + (props.format ?? 'html')
            + '&session=' + CookieRead(AppConfig.CookieName.LoggedSession);

        // Zobrazím cez preview dialog
        if (props.preview === true) {
            setPreviewSrc(printUrl);
        } else {
            FileOpen(printUrl, '_blank');
        }
    }, [props.open]);

    // Manuálna tlač
    const handlePrint = () => {
        var frame = document.getElementById('ReportDocumentFrame') as HTMLIFrameElement;
        if (frame) {
            frame.contentWindow?.print();
        }
    };

    return (
        <>
            <Dialog keepMounted={false} open={props.open} maxWidth="xl" fullWidth scroll="paper" onClose={props.onClose}>
                <DialogContent dividers={true} sx={{ p: 1, height: '80vh' }}>
                    <Iframe title={'Preview'} src={previewSrc} print={props.print} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{localization.back}</Button>
                    <Button onClick={handlePrint} variant="contained">{localization.print}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ReportDocument;