import { FormEvent, useCallback, useEffect, useState } from 'react'
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { GetLanguage, Localize, Localization } from '../../AppLocalization';

// Utility
import { nullMinDate } from '../../utility/Date';
import { ConvertToInt } from '../../utility/Convert';
import Debounce from '../../utility/Debounce';

// Modely
import { Condition, Customer, Color, Examination, Pony, Trophy, YearLetter } from '../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, Card, CardMedia, FormControl, InputLabel, MenuItem, Select, FormControlLabel, Radio, RadioGroup, Tab, Tabs, Typography, Chip } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TabContext, TabPanel } from '@mui/lab';
import { Locale } from 'date-fns';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import skLocale from 'date-fns/locale/sk';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr';

import PonyCreateFiles from './PonyCreateFiles';
import PonyCreateExaminations from './PonyCreateExaminations';
import PonyCreateTrophies from './PonyCreateTrophies';
import PonyCreateCustomers from './PonyCreateCustomers';
import PonyCreateNotes from './PonyCreateNotes';
import PonyCreateFamilyTree from './PonyCreateFamilyTree';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import HeightIcon from '@mui/icons-material/Height';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import { AppRouteUrl } from '../../AppRoutes';
import { useHistory } from 'react-router-dom';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): Pony => ({
    id: 0,
    name: '',
    genderMale: true,
    motherPonyId: 0,
    fatherPonyId: 0,
    dateOfBirth: undefined,
    dateOfDead: undefined,
    conditionId: 0,
    heightAtBirth: undefined,
    heightAtFinal: undefined,
    colorId: 0,
    sold: false,
    customers: [],
    examinations: [],
    files: [],
    notes: [],
    trophies: []
});

// Vstupné parametre
export interface PonyCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    readonly?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    customers: Customer[];
    yearletters: YearLetter[];
    conditions: Condition[];
    colors: Color[];
    examinations: Examination[];
    trophies: Trophy[];
    ponies: Pony[];
    onSave?: () => void;
    onClose: () => void;
}

// Spoločné vstupné parametre pre vnorené komponenty (aby som nemusel pri každom rozpisovať všetko spoločné)
export interface PonyCreateComponentProps {
    localization: Localization;
    readonly: boolean;
    source: Pony;
    setSource: (action: React.SetStateAction<Pony>) => void;
    onChange: (property: string, value: any) => void;
};

const PonyCreate = (props: PonyCreateProps) => {

    // Jazyková mutácia
    const localization = Localize();
    const history = useHistory();

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>({ ...EmptyWarning });
    const [source, setSource] = useState<Pony>({ ...EmptySource() });
    const [tabValue, setTabValue] = useState<string>('notes');
    const [nameExists, setNameExists] = useState<number | undefined>(0);

    // Kontrola duplicitného názvu poníka
    const findName = (name: string) => {
        if ((name ?? '').length === 0) {
            setNameExists(0);
            return;
        }
        setNameExists(undefined);
        findNameDelay(name);
    };
    const findNameDelay = Debounce((name: string) => {
        axios
            .get(AppConfig.ApiUri + 'pony/byname', {
                params: {
                    id: source.id ?? 0,
                    name: name
                }
            })
            .then(response => {
                if (response.data !== null) {
                    var nameId = response.data as number;
                    if (nameId > 0 && nameId === source.id) {
                        setNameExists(0);
                        return;
                    }
                    setNameExists(nameId);
                }
            });
    }, 1000);

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            // Vždy najskôr vyresetujem údaje (kvôli háčikom)
            setTabValue('notes');
            setSource({
                ...EmptySource(),
                // customers: [],
                // examinations: [],
                // files: [],
                // notes: [],
                // trophies: []
            });
            return;
        }
        if ((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'pony/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    let data = response.data as Pony;
                    data.dateOfBirth = nullMinDate(data.dateOfBirth) ?? undefined;
                    data.dateOfDead = nullMinDate(data.dateOfDead) ?? undefined;
                    if (props.copy) {
                        data.id = 0;
                        data.customers = [];
                        data.examinations = [];
                        data.files = [];
                        data.notes = [];
                        data.trophies = [];
                    }
                    setSource(data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        if (props.readonly) {
            return;
        }
        setSource(prev => ({ ...prev, [property]: value }));
        if (property === 'name') {
            findName(value);
        }
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        var data = { ...source };
        data.dateOfBirth = nullMinDate(data.dateOfBirth) ?? undefined;
        data.dateOfDead = nullMinDate(data.dateOfDead) ?? undefined;

        if (data.name === '') {
            setWarning({ ...EmptyWarning, name: 'name', text: localization.mustFill + ': ' + localization.name + '!' });
            return;
        }

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'pony', data)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: localization.saveFailed });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => input?.focus(), 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    // Vráti jazykovú mutáciu pre datepicker
    const dateLocale = (): Locale => {
        return GetLanguage() === 'en' ? enLocale :
            GetLanguage() === 'fr' ? frLocale :
                skLocale;
    };

    // Funkcia pre vygenerovanie spoločných vstupných parametrov (aby som nemusel pri každom rozpisovať všetko spoločné)
    const componentProps = (): PonyCreateComponentProps => {
        return {
            localization: localization,
            source: source,
            readonly: props.readonly ?? false,
            setSource: setSource,
            onChange: handleChange
        }
    };

    return (
        <>
            <Dialog PaperProps={{ style: { borderLeft: '10px solid ' + (source.genderMale ? '#97cdd2' : '#ffb4be') } }} keepMounted={props.keepMounted ?? false} maxWidth="md" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle sx={{ color: (source.genderMale ? '#00a6b6' : '#f52843') }}>
                        {((source?.id ?? 0) === 0 && localization.new + ': ')} {(source.name?.length ?? 0) > 0 ? source.name : localization.pony}
                        {(props.copy && ' (' + localization.copied + ')')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12}>
                                <RadioGroup row name="genderMale" value={source.genderMale ?? false} onChange={(e) => { handleChange(e.target.name, (e.target.value === 'true')); }}>
                                    <FormControlLabel disabled={props.readonly} value="true" control={<Radio />} label={localization.stallion} />
                                    <FormControlLabel disabled={props.readonly} value="false" control={<Radio />} label={localization.aMare} />
                                </RadioGroup>
                            </Grid>

                            <Grid item xs={12} md container columnSpacing={1}>

                                <Grid item xs={12}>
                                    <TextField disabled={props.readonly} required margin="dense" name="name" label={localization.name} fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                        inputProps={{ maxLength: 255 }}
                                        InputProps={{
                                            endAdornment: (
                                                nameExists === 0 ?
                                                    <Chip icon={<SentimentSatisfiedAltIcon />} color="success" variant="outlined" label={localization.alright} /> :
                                                    nameExists === undefined ?
                                                        <Chip icon={<CircularProgress size={22} color="inherit" />} color="info" variant="outlined" label={localization.verifying} />
                                                        :
                                                        <Chip icon={<ReportProblemIcon />} color="error" label={localization.exists + ' (' + localization.open.toLowerCase() + ')'} onClick={() => {
                                                            const id = nameExists;
                                                            handleChange('name', ''); // Najskôr musím vyčistiť číslo (nemusím mať právo záznamo zobraziť)
                                                            setNameExists(0); // Označím, že neexistuje (zatiaľ je prázdne)
                                                            history.push(AppRouteUrl.PONIES + '?id=' + id); // Zobrazím príslušný záznam podľa ID (ak nemám právo, vráti sa prázdna trieda)
                                                        }} />
                                            ),
                                        }} />
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl margin="dense" variant="outlined" fullWidth>
                                        <InputLabel id="source-color">{localization.color}</InputLabel>
                                        <Select disabled={props.readonly} labelId="source-color" label={localization.color} value={source.colorId ?? 0} name="colorId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => props.autoFocus === 'colorId' && setFocus(input)}>
                                            <MenuItem key={0} value={0}>-</MenuItem>
                                            {props.colors?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl margin="dense" variant="outlined" fullWidth>
                                        <InputLabel id="source-condition">{localization.condition}</InputLabel>
                                        <Select disabled={props.readonly} labelId="source-condition" label={localization.condition} value={source.conditionId ?? 0} name="conditionId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => props.autoFocus === 'conditionId' && setFocus(input)}>
                                            <MenuItem key={0} value={0}>-</MenuItem>
                                            {props.conditions?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6} md={3}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={dateLocale()}>
                                        <DatePicker disabled={props.readonly} inputFormat='dd.MM.yyyy' disableMaskedInput={false}
                                            renderInput={(props: any) => <TextField fullWidth margin="dense" autoComplete="off" variant="outlined" {...props} />} label={localization.dateOfBirth} value={source.dateOfBirth ?? null} onChange={(d: any) => { handleChange('dateOfBirth', d) }}
                                            inputRef={(input: any) => props.autoFocus === 'dateOfBirth' && setFocus(input)} />
                                    </LocalizationProvider>
                                </Grid>

                                <Grid item xs={6} md={3}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={dateLocale()}>
                                        <DatePicker disabled={props.readonly} inputFormat='dd.MM.yyyy' disableMaskedInput={false}
                                            renderInput={(props: any) => <TextField fullWidth margin="dense" autoComplete="off" variant="outlined" {...props} />} label={localization.dateOfDead} value={source.dateOfDead ?? null} onChange={(d: any) => { handleChange('dateOfDead', d) }}
                                            inputRef={(input: any) => props.autoFocus === 'dateOfDead' && setFocus(input)} />
                                    </LocalizationProvider>
                                </Grid>

                                <Grid item xs={6} md={3}>
                                    <TextField disabled={props.readonly} fullWidth margin="dense" name="heightAtBirth" type="number" label={localization.heightAtBirth} variant="outlined" value={(source.heightAtBirth ?? 0) > 0 ? source.heightAtBirth : ''} onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }}
                                        inputRef={input => props.autoFocus === 'heightAtBirth' && setFocus(input)}
                                        inputProps={{ min: 0, max: 32767 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <HeightIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>

                                <Grid item xs={6} md={3}>
                                    <TextField disabled={props.readonly} fullWidth margin="dense" name="heightAtFinal" type="number" label={localization.heightAtFinal} variant="outlined" value={(source.heightAtFinal ?? 0) > 0 ? source.heightAtFinal : ''} onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }}
                                        inputRef={input => props.autoFocus === 'heightAtFinal' && setFocus(input)}
                                        inputProps={{ min: 0, max: 32767 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <HeightIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>

                            </Grid>

                            <Grid item xs={12} md={3} sx={{ ml: { sx: 0, md: 1 } }}>
                                <Card sx={{ mt: 1 }}>
                                    {source?.files?.find(file => file.cover === true) !== undefined ? (
                                        <CardMedia component="img" height="192" image={source?.files?.find(file => file.cover === true)?.fileSrcMedium ?? ''} />
                                    ) : (
                                        <CardMedia component="div" sx={{ display: 'flex', height: { xs: '100px', md: '192px' }, justifyContent: 'center', alignItems: 'center' }}>
                                            <PhotoCameraIcon fontSize="large" sx={{ color: 'silver' }} />
                                        </CardMedia>
                                    )}
                                </Card>
                            </Grid>


                            <Grid item xs={12}>
                                <Typography variant="h5" textAlign="center" sx={{ my: 2 }}>{localization.production}</Typography>
                                <PonyCreateFamilyTree {...componentProps()} ponies={props.ponies} />
                            </Grid>

                            <Grid item xs={12} mt={3}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={tabValue} onChange={(e, v) => setTabValue(v)} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                                        <Tab value="notes" label={localization.notes + ' (' + (source.notes?.length ?? 0) + ')'} />
                                        <Tab value="files" label={localization.files + ' (' + (source.files?.length ?? 0) + ')'} />
                                        <Tab value="examinations" label={localization.tests + ' (' + (source.examinations?.length ?? 0) + ')'} />
                                        <Tab value="trophies" label={localization.trophies + ' (' + (source.trophies?.length ?? 0) + ')'} />
                                        <Tab value="owner" label={localization.owner + ' (' + (source.customers?.length ?? 0) + ')'} />
                                    </Tabs>
                                </Box>
                                <TabContext value={tabValue}>
                                    <TabPanel value="notes" sx={{ px: '0' }}>
                                        <PonyCreateNotes {...componentProps()} source={source} />
                                    </TabPanel>
                                    <TabPanel value="files" sx={{ px: '0' }}>
                                        <PonyCreateFiles {...componentProps()} />
                                    </TabPanel>
                                    <TabPanel value="examinations" sx={{ px: '0' }}>
                                        <PonyCreateExaminations {...componentProps()} source={source} examinations={props.examinations} />
                                    </TabPanel>
                                    <TabPanel value="trophies" sx={{ px: '0' }}>
                                        <PonyCreateTrophies {...componentProps()} source={source} trophies={props.trophies} />
                                    </TabPanel>
                                    <TabPanel value="owner" sx={{ px: '0' }}>
                                        <PonyCreateCustomers {...componentProps()} source={source} customers={props.customers} />
                                    </TabPanel>
                                </TabContext>
                            </Grid>
                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        {(props.readonly ?? false) === false && <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>{localization.reset}</Button>}
                        <Button disabled={loading} onClick={props.onClose}>{localization.back}</Button>
                        {(props.readonly ?? false) === false && <Button disabled={loading} type="submit" variant="contained">{localization.save}</Button>}
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default PonyCreate;