import { Localize } from '../AppLocalization';

// Ikony
import BarChartIcon from '@mui/icons-material/BarChart';
import SourceIcon from '@mui/icons-material/Source';
import PeopleIcon from '@mui/icons-material/People';
import TuneIcon from '@mui/icons-material/Tune';
import AbcIcon from '@mui/icons-material/Abc';
import CheckIcon from '@mui/icons-material/Check';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PublicIcon from '@mui/icons-material/Public';
import SaveIcon from '@mui/icons-material/Save';

// Podstránky
import { AppRouteUrl } from '../AppRoutes';
import Navigation, { Item } from '../components/Navigation';

// Vstupné parametre
interface SidebarProps {
    open: boolean;
    onClickToggle?: () => void;
}

const Sidebar = (props: SidebarProps) => {
    
    // Jazyková mutácia
    const localization = Localize();
    
    // Zoznam položiek menu 
    const items: Item[] = [
        { path: AppRouteUrl.STATISTICS, title: localization.statistics, icon: <BarChartIcon /> },
        { path: AppRouteUrl.PONIES, title: localization.ponies, icon: <SourceIcon /> },
        { path: AppRouteUrl.CUSTOMERS, title: localization.addressBook, icon: <PeopleIcon />, divider: true },
        { path: AppRouteUrl.YEARS, title: localization.settings, icon: <TuneIcon />, 
            items: 
            [
                { path: AppRouteUrl.USERS, title: localization.users, icon: <PeopleIcon /> },
                { path: AppRouteUrl.YEARS, title: localization.years, icon: <AbcIcon /> },
                { path: AppRouteUrl.CONDITIONS, title: localization.conditions, icon: <CheckIcon /> },
                { path: AppRouteUrl.COLORS, title: localization.colors, icon: <ColorLensIcon /> },
                { path: AppRouteUrl.EXAMINATIONS, title: localization.examinations, icon: <MedicalServicesIcon /> },
                { path: AppRouteUrl.TROPHIES, title: localization.trophiesAndTitles, icon: <EmojiEventsIcon /> },
                { path: AppRouteUrl.COUNTRIES, title: localization.countries, icon: <PublicIcon /> },
                { path: AppRouteUrl.BACKUPS, title: localization.backups, icon: <SaveIcon /> }
            ]
        }
    ];

    return <Navigation items={items} onClickToggle={props.onClickToggle} />
}

export default Sidebar;