import { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { AppRouteUrl } from '../../AppRoutes';
import { Localize } from '../../AppLocalization';
import { darken, lighten } from '@mui/material/styles';

// Modely
import { Condition, Color, Customer, Examination, Pony, Trophy, YearLetter, PonyFilter } from '../../models/Models';

// Utility
import { FindText } from '../../utility/Search';
import { Export as DataGridExport, ColumnsSettings as DataGridColumnSettings, LocalizeGrid } from '../../utility/DataGrid';
import { useQuery } from '../../utility/URL';
import { EscapeRegExp, RemoveAccents } from '../../utility/Text';
import { LoadLocalSettings, SaveLocalSettings } from '../../utility/LocalSettings';

// Komponenty
import { DataGrid, GridColDef, GridValueFormatterParams, GridRenderCellParams, GridValueGetterParams, GridFilterModel, GridRowId, GridDensity } from "@mui/x-data-grid";
import { Avatar, Box, Button, Divider, Grid, IconButton, ListItemIcon, Menu, Paper, ToggleButton, ToggleButtonGroup, Tooltip, Typography, useTheme } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Search from '../../components/Search';
import { Content, ContentTop, ContentBottom } from '../../layout/Content';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import PonyCreate, { PonyCreateProps } from './PonyCreate';
import PoniesFiltering from './PoniesFiltering';
import ReportDocument, { ReportDocumentProps } from '../report/ReportDocument';
import PonyTreeDialog, { PonyTreeDialogProps } from '../ponytree/PonyTreeDialog';

// Ikony
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import ClearIcon from '@mui/icons-material/Clear';
import GridOnIcon from '@mui/icons-material/GridOn';
import GridViewIcon from '@mui/icons-material/GridView';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import PrintIcon from '@mui/icons-material/Print';
import PolylineIcon from '@mui/icons-material/Polyline';

// Komponent pre zoznam
const Ponies = () => {
    const history = useHistory();
    const theme = useTheme();
    const localization = Localize();

    // Lokálny stav
    const [loading, setLoading] = useState<boolean>(true);
    const [search, setSearch] = useState('');
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [ponyCreate, setPonyCreate] = useState<PonyCreateProps>({
        open: false,
        keepMounted: true,
        customers: [],
        yearletters: [],
        conditions: [],
        colors: [],
        examinations: [],
        trophies: [],
        ponies: [],
        onSave: () => loadData(),
        onClose: () => setPonyCreate(prev => ({ ...prev, open: false }))
    });
    const [ponyTreeDialog, setPonyTreeDialog] = useState<PonyTreeDialogProps>({
        pony: {},
        ponies: [],
        levels: 5,
        open: false,
        onClose: () => setPonyTreeDialog(prev => ({ ...prev, open: false }))
    });

    // Nastavenie zobrazenia    
    const [display, setDisplay] = useState<GridDensity>(LoadLocalSettings('datagrid-display', 'standard') as GridDensity);
    const setDisplayAndSave = (value: string) => {
        setDisplay(value as GridDensity);
        SaveLocalSettings('datagrid-display', value, 'standard');
    };

    // Nastavenia stĺpcov
    const columnsSettings = new DataGridColumnSettings({ uid: 'ponies' });
    const columnsDefault: GridColDef[] = [
        { field: 'id', headerName: 'Id', hide: true, minWidth: 20, width: 90, type: 'number', align: 'center', headerAlign: 'center' },
        {
            field: 'file', headerName: localization.preview, hide: false, width: 80, sortable: false, filterable: false, align: 'center', headerAlign: 'center',
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => (
                <>
                    {params.row.fileIsImage ?
                        <Avatar sx={{ outline: (params.row.genderMale ? 'solid 3px #00a6b6' : 'solid 3px #f52843') }} alt={params.row.name} src={params.row.fileSrcSmall} /> :
                        <Avatar sx={{ outline: (params.row.genderMale ? 'solid 3px #00a6b6' : 'solid 3px #f52843') }}><PhotoCameraIcon /></Avatar>}
                </>
            )
        },
        { field: 'sold', headerName: localization.sold, hide: true, width: 70, type: 'boolean', sortable: false },
        { field: 'dead', headerName: localization.dead, hide: true, width: 70, type: 'boolean', sortable: false },
        {
            field: 'name', headerName: localization.name, hide: false, minWidth: 50, flex: 1,
            renderCell: (params: GridRenderCellParams<number>) => <strong>{params?.value}</strong>
        },
        {
            field: 'year', headerName: localization.year, hide: false, minWidth: 50, flex: 0.8,
            valueGetter: (params: GridValueGetterParams) =>
                ((params.row.dateOfBirthYear ?? 0) > 100 ? params.row.dateOfBirthYear : '-') +
                ((params.row.dateOfBirthLetter?.length ?? 0) > 0 ? ' ' + params.row.dateOfBirthLetter : '') + ' / ' +
                ((params.row.dateOfDeadYear ?? 0) > 100 ? params.row.dateOfDeadYear : '-')
        },
        {
            field: 'height', headerName: localization.height, hide: false, minWidth: 50, flex: 0.8,
            valueGetter: (params: GridValueGetterParams) =>
                ((params.row.heightAtBirth ?? 0) > 0 ? params.row.heightAtBirth : '-') + ' / ' +
                ((params.row.heightAtFinal ?? 0) > 0 ? params.row.heightAtFinal : '-') + ' cm'
        },
        { field: 'fatherPonyName', headerName: localization.father, hide: false, minWidth: 50, flex: 0.8 },
        { field: 'motherPonyName', headerName: localization.mother, hide: false, minWidth: 50, flex: 0.8 },
        { field: 'colorName', headerName: localization.color, hide: false, minWidth: 50, flex: 0.8 },
        { field: 'customerName', headerName: localization.owner, hide: false, minWidth: 50, flex: 0.8 },
        { field: 'conditionName', headerName: localization.condition, hide: false, minWidth: 50, flex: 0.8 },
        {
            field: 'createdDate', headerName: localization.created, hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['createdDate']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? (params?.value as Date).toLocaleDateString() : '-')
        },
        {
            field: 'updatedDate', headerName: localization.updated, hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => (new Date(params.row['updatedDate'])),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? (params?.value as Date).toLocaleDateString() : '-')
        },
        {
            field: 'options', headerName: localization.options, hide: false, width: 170, sortable: false, filterable: false, align: 'right', headerAlign: 'center',
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => (
                <>
                    <IconButton aria-label={localization.print} title={localization.print} size="small" onClick={(e) => handlePrintMenu(params.value, e.currentTarget)}>
                        <PrintIcon color="info" fontSize="small" />
                    </IconButton>
                    <IconButton aria-label={localization.famailyTree} title={localization.famailyTree} size="small" onClick={() => handlePonyTree(params.row as Pony)}>
                        <PolylineIcon color="primary" fontSize="small" />
                    </IconButton>
                    <IconButton aria-label={localization.edit} title={localization.edit + ' (enter)'} size="small" onClick={() => handleCreate(params.value, false)}>
                        <EditIcon color="primary" fontSize="small" />
                    </IconButton>
                    <IconButton aria-label={localization.copy} title={localization.copy + ' (ctrl + enter)'} size="small" onClick={() => handleCreate(params.value, true)}>
                        <ContentCopyIcon fontSize="small" />
                    </IconButton>
                    <IconButton aria-label={localization.delete} title={localization.delete + ' (delete)'} size="small" onClick={() => handleDelete(params.value, params.row.name)}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </>
            )
        }
    ];

    // Aplikujem uložené nastavenia
    useEffect(() => setColumns(columnsSettings.apply(columns)), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Tabuľka
    const [rows, setRows] = useState<Pony[]>([]);
    const [rowsSelected, setRowsSelected] = useState<GridRowId[]>([])
    const [rowsSelectedMenuEl, setRowsSelectedMenuEl] = useState<null | HTMLElement>(null);
    const [rowsPageSize, setRowsPageSize] = useState<number>(25);
    const [rowsFilter, setRowsFilter] = useState<PonyFilter>({});
    const [rowsFilterHeightAtBirthData, setRowsFilterHeightAtBirthData] = useState<number[]>([]);
    const [rowsFilterHeightAtFinalData, setRowsFilterHeightAtFinalData] = useState<number[]>([]);
    const [rowsFiltered, setRowsFiltered] = useState<Pony[]>([]);
    const [columns, setColumns] = useState<GridColDef[]>(columnsDefault);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), []);

    // Pridať upraviť záznam
    const handleCreate = (id: number, copy?: boolean, field?: string, readonly?: boolean) => {
        setPonyCreate(prev => ({
            ...prev,
            id: id,
            copy: copy ?? false,
            readonly: readonly ?? false,
            open: true,
            autoFocus: field
        }));
        if (ponyTreeDialog.open) {
            setPonyTreeDialog(prev => ({ ...prev, open: false }));
        }
    };

    // Zobraziť mapu
    const handlePonyTree = (pony: Pony) => {
        setPonyTreeDialog(prev => ({
            ...prev,
            open: true,
            pony: pony
        }));
    };

    // Úprava záznamu podľa "id" v URL
    const requestId: number = parseInt(useQuery().get('id') ?? '0');
    useEffect(() => {
        if (requestId > 0) {
            history.push(AppRouteUrl.PONIES); // Vynuluje URL (inak by F5 znovu vyvolalo funkciu)
            handleCreate(requestId);
        }
    }, [requestId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Zobrazenie rodokmeňu "id" v URL
    const requestTreeId: number = parseInt(useQuery().get('tree') ?? '0');
    useEffect(() => {
        if (requestTreeId > 0) {
            history.push(AppRouteUrl.PONIES); // Vynuluje URL (inak by F5 znovu vyvolalo funkciu)
            const pony = (rows.find(item => item.id === requestTreeId) ?? {}) as Pony;
            if ((pony?.id ?? 0) > 0) {
                handlePonyTree(pony);
            }
        }
    }, [requestTreeId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Vymazať záznam
    const handleDelete = (id: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: localization.deleteConfirmation, onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'pony/' + id)
                    .then(response => {
                        if (response.data === true) {
                            setRows(prev => [...prev.filter(r => r.id !== id)]);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };
    const handleDeleteList = (ids: number[]) => {
        if (ids.length === 0) {
            return;
        }
        setConfirm(prev => ({
            ...prev, open: true, title: localization.delete + ': ' + ids.length, children: localization.deleteSeletedConfirmation, onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'pony/list', {
                        params: {
                            'ids': ids
                        }
                    })
                    .then(response => {
                        if (response.data === true) {
                            setRows(prev => [...prev.filter(r => !ids.includes(r?.id ?? 0))]);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    const handleExportCSV = () => {
        if (rowsSelected.length === 0) {
            return;
        }
        DataGridExport({
            type: 'csv',
            columns: columns,
            columnsSkip: ['options', 'file'],
            rows: rows,
            ids: rowsSelected.map(r => r as number),
            specific: [{
                field: 'year',
                getValue: (row: any) => {
                    return ((row.dateOfBirthYear ?? 0) > 100 ? row.dateOfBirthYear : '-') +
                        ((row.dateOfBirthLetter?.length ?? 0) > 0 ? ' ' + row.dateOfBirthLetter : '') + ' / ' +
                        ((row.dateOfDeadYear ?? 0) > 100 ? row.dateOfDeadYear : '-');
                }
            }, {
                field: 'height',
                getValue: (row: any) => {
                    return ((row.heightAtBirth ?? 0) > 0 ? row.heightAtBirth : '-') + ' / ' +
                        ((row.heightAtFinal ?? 0) > 0 ? row.heightAtFinal : '-') + ' cm';
                }
            }]
        });
    };
    const handleExportXML = () => {
        if (rowsSelected.length === 0) {
            return;
        }
        DataGridExport({
            type: 'xml',
            columns: columns,
            columnsSkip: ['options', 'file'],
            rows: rows,
            ids: rowsSelected.map(r => r as number),
            specific: [{
                field: 'year',
                getValue: (row: any) => {
                    return ((row.dateOfBirthYear ?? 0) > 100 ? row.dateOfBirthYear : '-') +
                        ((row.dateOfBirthLetter?.length ?? 0) > 0 ? ' ' + row.dateOfBirthLetter : '') + ' / ' +
                        ((row.dateOfDeadYear ?? 0) > 100 ? row.dateOfDeadYear : '-');
                }
            }, {
                field: 'height',
                getValue: (row: any) => {
                    return ((row.heightAtBirth ?? 0) > 0 ? row.heightAtBirth : '-') + ' / ' +
                        ((row.heightAtFinal ?? 0) > 0 ? row.heightAtFinal : '-') + ' cm';
                }
            }]
        });
    };

    // Funkcia pre načítanie dát z API
    const loadData = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'pony')
            .then(response => {
                setRows(response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Číselníky
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [yearletters, setYearLetters] = useState<YearLetter[]>([]);
    const [conditions, setConditions] = useState<Condition[]>([]);
    const [colors, setColors] = useState<Color[]>([]);
    const [examinations, setExaminations] = useState<Examination[]>([]);
    const [trophies, setTrophies] = useState<Trophy[]>([]);

    // Filtrácia podľa aktuálneho filtra
    const applyFilter = (rows: Pony[]): Pony[] => {

        // Názov odfiltrujem v jednom kroku
        if ((rowsFilter.name?.length ?? 0) > 0) {
            const nameRegex = new RegExp(EscapeRegExp(RemoveAccents((rowsFilter.name ?? '').toLowerCase())), 'i');
            rows = rows.filter(row => nameRegex.test(RemoveAccents(row.name?.toLowerCase() ?? '')));
        }

        // Ostatné filtre môžem zreťaziť
        const filterYear = (row: Pony): boolean => ((rowsFilter.yearOfBirth ?? 0) === 0 || ((rowsFilter.yearOfBirth ?? 0) > 0 && ((rowsFilter.yearOfBirth ?? 0) === row.dateOfBirthYear)));
        const filterColor = (row: Pony): boolean => ((rowsFilter.colorId ?? 0) === 0 || ((rowsFilter.colorId ?? 0) > 0 && ((rowsFilter.colorId ?? 0) === row.colorId)));

        const filterExamination = (row: Pony): boolean => ((rowsFilter.examinationsId ?? 0) === 0 || ((rowsFilter.examinationsId ?? 0) > 0 && (row.examinationsIds ?? [])?.includes((rowsFilter.examinationsId ?? 0))));
        const filterTrophy = (row: Pony): boolean => ((rowsFilter.trophyId ?? 0) === 0 || ((rowsFilter.trophyId ?? 0) > 0 && (row.trophiesIds ?? [])?.includes((rowsFilter.trophyId ?? 0))));

        const filterCustomer = (row: Pony): boolean => ((rowsFilter.customerId ?? 0) === 0 || ((rowsFilter.customerId ?? 0) > 0 && ((rowsFilter.customerId ?? 0) === row.customerId)));
        const filterCondition = (row: Pony): boolean => ((rowsFilter.conditionId ?? 0) === 0 || ((rowsFilter.conditionId ?? 0) > 0 && ((rowsFilter.conditionId ?? 0) === row.conditionId)));
        const filterGender = (row: Pony): boolean => (rowsFilter.genderMale === undefined || (rowsFilter.genderMale !== undefined && (rowsFilter.genderMale === row.genderMale)));
        const filterParent = (row: Pony): boolean => ((rowsFilter.parentPonyId ?? 0) === 0 || ((rowsFilter.parentPonyId ?? 0) > 0 && (((rowsFilter.parentPonyId ?? 0) === row.fatherPonyId) || ((rowsFilter.parentPonyId ?? 0) === row.motherPonyId))));
        const filterHeightAtBirth = (row: Pony): boolean => ((rowsFilter.heightAtBirthFrom ?? 0) === 0 || ((rowsFilter.heightAtBirthFrom ?? 0) > 0 && ((rowsFilter.heightAtBirthFrom ?? 0) <= (row.heightAtBirth ?? 0) && ((rowsFilter.heightAtBirthTo ?? 0) >= (row.heightAtBirth ?? 0)))));
        const filterHeightAtFinal = (row: Pony): boolean => ((rowsFilter.heightAtFinalFrom ?? 0) === 0 || ((rowsFilter.heightAtFinalFrom ?? 0) > 0 && ((rowsFilter.heightAtFinalFrom ?? 0) <= (row.heightAtFinal ?? 0) && ((rowsFilter.heightAtFinalTo ?? 0) >= (row.heightAtFinal ?? 0)))));

        // Dorobiť vyšetrenia, trofeje ako pole
        rows = rows.filter(row =>
            filterYear(row) &&
            filterColor(row) &&
            filterExamination(row) &&
            filterTrophy(row) &&
            filterCustomer(row) &&
            filterCondition(row) &&
            filterGender(row) &&
            filterParent(row) &&
            filterHeightAtBirth(row) &&
            filterHeightAtFinal(row)
        );
        return rows;
    };

    // Vyhľadávanie (automaticky odfiltrujem záznamy po zmene hľadaného textu, alebo obnovení riadkov)
    useEffect(() => setRowsFiltered(applyFilter((search.length > 0 ? FindText(rows, search, ['name']) : rows))), [rows, rowsFilter, search]);

    // Funkcia pre naplnenie stavu číselníkov
    const applyFormData = (data: any) => {
        if (data.customer) {
            setCustomers(data.customer);
        }
        if (data.yearletter) {
            setYearLetters(data.yearletter);
        }
        if (data.condition) {
            setConditions(data.condition);
        }
        if (data.color) {
            setColors(data.color);
        }
        if (data.examination) {
            setExaminations(data.examination);
        }
        if (data.trophy) {
            setTrophies(data.trophy);
        }
    };

    // Načítam dáta pre formulár jedným volaním
    const loadFormData = () => {
        axios
            .get(AppConfig.ApiUri + 'pony/formdata')
            .then(response => {
                if (response.data) {
                    applyFormData(response.data);
                }
            });
    };
    useEffect(() => loadFormData(), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Vždy keď sa zmení zoznam záznamov, tak prepočítam počty podľa výšky pre graf
    useEffect(() => {
        const heightAtBirthMin = 25, heightAtBirthMax = 55;
        const heightAtFinalMin = 55, heightAtFinalMax = 105;
        const heightAtBirthData = [], heightAtFinalData = [];
        if (rows.length > 0) {
            for (let i = heightAtBirthMin; i <= heightAtBirthMax; i++) {
                heightAtBirthData.push(rows.filter(row => row.heightAtBirth === i)?.length ?? 0);
            }
            for (let i = heightAtFinalMin; i <= heightAtFinalMax; i++) {
                heightAtFinalData.push(rows.filter(row => row.heightAtFinal === i)?.length ?? 0);
            }
        }
        setRowsFilterHeightAtBirthData(heightAtBirthData);
        setRowsFilterHeightAtFinalData(heightAtFinalData);
    }, [rows]);

    // Prepočíta farbu (zosvetlenie alebo stmavenie, napr. pre hover)
    const getHoverBackgroundColor = (color: string, mode: string) =>
        mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

    // Tlač dokladu
    const [documentPrintMenuEl, setDocumentPrintMenuEl] = useState<null | HTMLElement>(null);
    const [documentPrint, setReportDocument] = useState<ReportDocumentProps>({
        documentId: 0,
        documentTypeId: 0,
        open: false,
        preview: true,
        print: true,
        format: 'html',
        onClose: () => setReportDocument(prev => ({ ...prev, open: false }))
    })
    const handlePrintMenu = (id: number, target: null | HTMLElement) => {
        if (id > 0) {
            setDocumentPrintMenuEl(target);
            setReportDocument(prev => ({ ...prev, documentId: id }));
            return;
        }
    };
    const handlePrint = (typeId: number) => {
        setDocumentPrintMenuEl(null);
        if (typeId > 0) {
            setReportDocument(prev => ({ ...prev, documentTypeId: typeId, open: true }));
            return;
        }
    };

    return (
        <>
            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Formulár pre nový záznam */}
            <PonyCreate {...ponyCreate} customers={customers} yearletters={yearletters} conditions={conditions} colors={colors} examinations={examinations} trophies={trophies} ponies={rows} />

            {/* Formulár pre rodokmeň */}
            <PonyTreeDialog {...ponyTreeDialog} ponies={rows} />

            {/* Obsah */}
            <Content>
                <ContentTop>
                    <Grid container alignItems="center">

                        {/* Možnosti */}
                        <Grid item xs={9} md lg>

                            {/* Nový záznam */}
                            <Button variant="contained" sx={{ mr: 1 }} size="large" startIcon={<AddIcon />} onClick={() => handleCreate(0)}>{localization.newRecord}</Button>

                            {/* Označené záznamy (možnosti) */}
                            <Button variant="text" size="large" disabled={rowsSelected?.length === 0} aria-controls="menu-selected" aria-haspopup="true" onClick={(e) => setRowsSelectedMenuEl(e.currentTarget)} endIcon={<ExpandMoreIcon />}>{localization.selected} {'(' + rowsSelected.length.toString() + ')'}</Button>
                            <Menu id="menu-selected" anchorEl={rowsSelectedMenuEl} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} sx={{ mt: '55px' }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} open={Boolean(rowsSelectedMenuEl)} onClose={() => setRowsSelectedMenuEl(null)} >
                                <MenuItem onClick={() => { handleExportCSV(); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> {localization.saveAs} CSV (Excel)
                                </MenuItem>
                                <MenuItem onClick={() => { handleExportXML(); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> {localization.saveAs} XML
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { handleDeleteList(rowsSelected.map(r => r as number)); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon> {localization.delete}
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { setRowsSelected([]); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><ClearIcon fontSize="small" /></ListItemIcon> {localization.cancelSelection}
                                </MenuItem>
                            </Menu>
                        </Grid>

                        {/* Zobrazenie */}
                        <Grid item xs md={2} textAlign="right">
                            <ToggleButtonGroup exclusive value={display} size="small" sx={{ mr: { xs: 0, md: 1 }, backgroundColor: theme.layout.content.backgroundColor }} onChange={(e: any, d) => { if (d !== null) { setDisplayAndSave(d); } }}>
                                <ToggleButton value="compact" aria-label={localization.compact}>
                                    <Tooltip title={localization.compact}>
                                        <GridOnIcon />
                                    </Tooltip>
                                </ToggleButton>
                                <ToggleButton value="standard" aria-label={localization.normal}>
                                    <Tooltip title={localization.normal}>
                                        <GridViewIcon />
                                    </Tooltip>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>

                        {/* Vyhľadávanie */}
                        <Grid item xs={12} md={4} sx={{ mt: { xs: 1, md: 0 } }}>
                            <Search onSearch={s => setSearch(s)}
                                onClear={() => {
                                    setSearch('');
                                    setFilterModel({ items: [], linkOperator: undefined });
                                }}
                                autoFocus={true} />
                        </Grid>
                    </Grid>
                </ContentTop>
                <ContentBottom>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <Grid container columnSpacing={1}>
                            <Grid item xs={12} sm={5} lg={4} xl={3}>
                                <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                                    <Paper variant="outlined" sx={{ p: 1, position: 'absolute', width: '100%', height: '100%' }}>
                                        <Box sx={{ width: '100%', height: '100%', overflowY: 'auto' }}>
                                            <Typography mt={1} textAlign="center" variant="h6">{localization.search}</Typography>
                                            <PoniesFiltering
                                                ponies={rows} customers={customers} yearletters={yearletters} conditions={conditions} colors={colors} examinations={examinations} trophies={trophies}
                                                heightAtBirthData={rowsFilterHeightAtBirthData}
                                                heightAtFinalData={rowsFilterHeightAtFinalData}
                                                filter={rowsFilter}
                                                onSave={(filter) => setRowsFilter(filter)}
                                                onReset={() => setRowsFilter({})}
                                            />
                                        </Box>
                                    </Paper>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm lg xl>
                                <Box sx={{
                                    height: '100%', width: '100%',
                                    '& .gridstyle-male': { color: '#00a6b6', borderLeft: '3px solid #00a6b6' },
                                    '& .gridstyle-female': { color: '#f52843', borderLeft: '3px solid #f52843' },
                                    '& .gridstyle-sold': {
                                        bgcolor: (theme) => '#fff8e9',
                                        '&:hover': {
                                            bgcolor: (theme) => getHoverBackgroundColor('#fff8e9', theme.palette.mode),
                                        },
                                    },
                                    '& .gridstyle-dead': {
                                        bgcolor: (theme) => '#f3f4f5',
                                        borderLeft: '3px solid #666666',
                                        '&:hover': {
                                            bgcolor: (theme) => getHoverBackgroundColor('#f3f4f5', theme.palette.mode),
                                        },
                                    },
                                }}>
                                    <DataGrid
                                        getRowClassName={(params) => {
                                            let classNames = (params.row.genderMale === true ? 'gridstyle-male' : 'gridstyle-female');
                                            if (params.row.sold === true) {
                                                classNames += ' gridstyle-sold';
                                            }
                                            if (params.row.dead === true) {
                                                classNames += ' gridstyle-dead';
                                            }
                                            return classNames;
                                        }}
                                        density={display}
                                        checkboxSelection
                                        disableSelectionOnClick
                                        columns={columns}
                                        rows={rowsFiltered}
                                        localeText={LocalizeGrid()}
                                        loading={loading}

                                        disableVirtualization
                                        pageSize={rowsPageSize}
                                        onPageSizeChange={(pageSize) => setRowsPageSize(pageSize)}
                                        rowsPerPageOptions={[10, 25, 50]}

                                        // Dvoj-klik (úprava)
                                        onCellDoubleClick={(e) => {
                                            handleCreate(e.row.id, false, e.field, true);
                                        }}

                                        // Klávesnica (shift+enter => upraviť, shift+delete => vymazať, shift+space => označiť, vstavaná funkcia)
                                        onCellKeyDown={(e, c) => {
                                            if (c.code === 'Enter' && c.ctrlKey) {
                                                c.preventDefault();
                                                c.stopPropagation();
                                                handleCreate(e.row.id, true, e.field);
                                                return;
                                            }
                                            if (c.code === 'Enter' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                                c.preventDefault();
                                                c.stopPropagation();
                                                handleCreate(e.row.id, false, e.field);
                                                return;
                                            }
                                            if (c.code === 'Delete' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                                c.preventDefault();
                                                c.stopPropagation();
                                                handleDelete(e.row.id, e.row.name);
                                                return;
                                            }
                                        }}

                                        // Filtrácia
                                        filterModel={filterModel}
                                        onFilterModelChange={e => setFilterModel(e)}

                                        // Vybrané záznamy
                                        selectionModel={rowsSelected}
                                        onSelectionModelChange={e => setRowsSelected(e)}

                                        // Stĺpce (automatické ukladanie nastavení)
                                        onColumnVisibilityChange={e => columnsSettings.columnVisibilityChanged(e, columnsDefault)}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                </ContentBottom>
            </Content>

            {/* Tlač dokladu (možnosti) */}
            <Menu anchorEl={documentPrintMenuEl} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} transformOrigin={{ vertical: 'top', horizontal: 'right', }} open={Boolean(documentPrintMenuEl)} onClose={() => setDocumentPrintMenuEl(null)} >
                <MenuItem onClick={() => { handlePrint(1); }}>
                    <ListItemIcon><PrintIcon fontSize="small" /></ListItemIcon> {localization.informationSheet}
                </MenuItem>
                <MenuItem onClick={() => { handlePrint(2); }}>
                    <ListItemIcon><PrintIcon fontSize="small" /></ListItemIcon> {localization.productionReport}
                </MenuItem>
            </Menu>

            {/* Tlač dokladu */}
            <ReportDocument {...documentPrint} />
        </>
    )
}

export default Ponies;