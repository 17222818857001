import React, { useState } from 'react';
import { Localize } from '../../AppLocalization';

// Modely
import { Customer, PonyCustomer } from '../../models/Models';
import { PonyCreateComponentProps } from './PonyCreate';
import PonyCreateCustomer, { PonyCreateCustomerProps } from './PonyCreateCustomer';

// Utility
import { format as dateFormat } from 'date-fns';

// Komponenty
import { Alert, AlertTitle, Button, FormControlLabel, Grid, IconButton, Switch } from '@mui/material';
import Confirm, { ConfirmProps } from '../../components/Confirm';

// Ikony
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonIcon from '@mui/icons-material/Person';

// Vstupné parametre
export interface PonyCreateCustomersProps extends PonyCreateComponentProps {
    customers: Customer[];
}

const PonyCreateCustomers = (props: PonyCreateCustomersProps) => {

    // Jazyková mutácia
    const localization = Localize();

    // Lokálny stav
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });

    // Stav (editácia položky)
    const [orderCreatePayment, setPonyCreateCustomer] = useState<PonyCreateCustomerProps>({
        open: false,
        customers: [],
        onSave: (customer, customerIndex) => {
            props.setSource(prev => {
                let customers: PonyCustomer[] = [];

                // Aktualizujem alebo vytvorím záznam
                if (customerIndex !== undefined && customerIndex >= 0) {
                    customers = [...prev.customers ?? []];
                    customers[customerIndex] = { ...customer };
                }
                else {
                    customers = [...prev.customers ?? [], customer];
                }

                // Vrátim aktualizované záznamy
                return ({ ...prev, customers: customers });
            });
        },
        onClose: () => setPonyCreateCustomer(prev => ({ ...prev, open: false }))
    });

    // Pridať upraviť záznam
    const handleCreate = (index?: number) => {
        let customer: PonyCustomer | undefined = undefined;

        // Iba na čítanie
        if (props.readonly) {
            return;
        }

        // Úprava položky podľa indexu
        if (index !== undefined && index >= 0) {
            customer = (props.source?.customers ?? [])[index ?? 0];
        }

        setPonyCreateCustomer(prev => ({
            ...prev,
            customer: customer,
            customerIndex: index,
            open: true
        }));
    };

    // Vymazať záznam
    const handleDelete = (index: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: localization.deleteConfirmation, onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                props.setSource(prev => {
                    const customers = prev.customers ?? [];
                    return ({ ...prev, customers: [...customers.filter(customer => customer !== customers[index]) ?? []] });
                });
            }
        }));
    };

    // Zoradenie položiek
    const dateSortComparer = (a: PonyCustomer, b: PonyCustomer): number => {
        if (new Date(a.date ?? 0) !== new Date(b.date ?? 0)) {
            return new Date(a.date ?? 0) > new Date(b.date ?? 0) ? 1 : -1;
        }
        else {
            return (a.customerId ?? 0) > (b.customerId ?? 0) ? 1 : -1;
        }
    }

    return (
        <>

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Nový záznam */}
            <PonyCreateCustomer {...orderCreatePayment} customers={props.customers} />

            <Grid container columnSpacing={1}>

                <Grid item xs={12} mb={1}>
                    {props.readonly === false && (
                        <Grid item xs={12} mb={1}>
                            <Button variant="contained" size="small" startIcon={<AddIcon />} color="secondary" sx={{ mr: 1 }} aria-label={localization.newRecord} aria-controls="menu-add" aria-haspopup="true" onClick={() => handleCreate()}>{localization.newRecord}</Button>
                        </Grid>
                    )}
                    <FormControlLabel sx={{ ml: 1 }} control={<Switch disabled={props.readonly} checked={props.source.sold ?? false} name="sold" size="small" onChange={(e) => { props.onChange(e.target.name, e.target.checked) }} />} label={localization.sold} />
                </Grid>

                {/* Zoznam */}
                {
                    props.source.customers?.sort((a, b) => dateSortComparer(a, b))?.map((customer, index) => {
                        return (
                            <Grid item xs={12} mt={1} key={index}>
                                <Alert
                                    severity={index === ((props.source.customers?.length ?? 1) - 1) ? 'success' : 'info'}
                                    icon={<PersonIcon />}
                                    onDoubleClick={() => handleCreate(index)}
                                    action={
                                        <>
                                            {props.readonly === false && (<>
                                                <IconButton aria-label={localization.edit} color="inherit" size="small" onClick={() => handleCreate(index)}>
                                                    <EditIcon fontSize="inherit" />
                                                </IconButton>
                                                <IconButton aria-label={localization.delete} color="inherit" size="small" onClick={() => handleDelete(index, localization.customer)}>
                                                    <DeleteIcon fontSize="inherit" />
                                                </IconButton>
                                            </>)}
                                        </>
                                    }>
                                    <AlertTitle>
                                        <strong>{props.customers.find(ex => ex.id === customer.customerId)?.name ?? ''}</strong>
                                        {index === ((props.source.customers?.length ?? 1) - 1) ? ' (' + localization.currentOwner.toLowerCase() + ')' : ''}
                                    </AlertTitle>
                                    {dateFormat(new Date(customer.date ?? new Date()), 'dd.MM.yyyy')}
                                    {(customer.note?.length ?? 0) > 0 ? <><br />{customer.note}</> : null}
                                </Alert>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    )
}

export default PonyCreateCustomers;