import { createStyles, makeStyles, useTheme } from '@mui/styles';

const useStyles = makeStyles((theme: any) => createStyles({
    '@global': {
        '*': {
            boxSizing: 'border-box',
            margin: 0,
            padding: 0,
        },
        html: {
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
            height: '100%',
            width: '100%'
        },
        body: {
            height: '100%',
            width: '100%',
            cursor: 'default'
        },
        a: {
            textDecoration: 'none'
        },
        '#root': {
            height: '100%',
            width: '100%'
        },

        /* Scrollbar */
        '*::-webkit-scrollbar': {
            width: '0.3em',
            height: '0.8em'
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: '#666666'
        },

        /* Form */
        '.formIconHelp': {
            cursor: 'help'
        },

        /* Components > Navigation */
        '.navigation-collapse-child': {
            position: 'relative',
            '&:before': {
                display: 'block',
                width: '5px',
                height: '100%',
                position: 'absolute',
                top: '0px',
                left: '0px',
                content: '""',
                background: theme.layout.sidebar.collapse.borderColor,
            }
        },

        /* Components > ButtonList */
        '.buttonlist-collapse-child': {
            position: 'relative',
            '&:before': {
                display: 'block',
                width: '5px',
                height: '100%',
                position: 'absolute',
                top: '0px',
                left: '0px',
                content: '""',
                background: theme.palette.secondary.light,
            }
        },

        /* FamilyTree */
        '.ponytree-node': {
            position: 'relative',
            paddingLeft: '65px',
            height: '70px',
            lineHeight: '70px',
            cursor: 'pointer',
            '&:hover > .ponytree-node-image': {
                outlineWidth: '5px'
            }
        },
        '.ponytree-node-image': {
            position: 'absolute',
            top: '50%',
            left: '5px',
            marginTop: '-25px',
            width: '50px',
            height: '50px',
            backgroundSize: 'cover',
            objectFit: 'cover',
            borderRadius: '25px',
            outline: '2px solid silver'
        },
        '.ponytree-node-title': {
            display: 'inline-block',
            verticalAlign: 'middle',
            lineHeight: '20px'
        },
        '.ponytree-node-name': {
            display: 'block',
            fontWeight: '600'
        },
        '.ponytree-node-info': {
            display: 'block'
        },
        '.ponytree-node-edit': {
            color: 'silver',
            padding: '2px 3px',
            marginLeft: '-3px',
            '&:hover': {
                color: 'black'
            }
        },
    },
}));

const StylesGlobal = () => {
    const theme = useTheme();
    useStyles(theme);
    return null;
};

export default StylesGlobal;