
export const ExportCSV = (data: any[], delimiter?: string, delimiterValue?: string) => {
    if(delimiter === undefined) {
        delimiter = ',';
    }
    if(delimiterValue === undefined) {
        delimiterValue = '"';
    }
    var content = 'data:text/csv;charset=utf-8,';
    data.filter(item => item).forEach(item => {
        if(Array.isArray(item)) {
            var row = '';
            item.forEach(value => {
                row += (row !== '' ? delimiter : '');
                if(typeof value === 'object') {
                    row += delimiter;            
                }
                else {
                    row += (value?.toString().indexOf(delimiterValue) !== -1 ? delimiterValue : '');
                    row += (value?.toString() ?? '').replace(/(?:\\[rn]|[\r\n]+)+/g, '').replace(RegExp(delimiterValue ?? '', 'g'), (delimiterValue ?? '') + (delimiterValue ?? ''));
                    row += (value?.toString().indexOf(delimiterValue) !== -1 ? delimiterValue : '');
                }
            });
            content += row + '\r\n';
        }
    });
    var encodedUri = encodeURI(content);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", document.title + ".csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
