import React, { useEffect, useRef, useState } from 'react';
import { Localize } from '../../AppLocalization';

// Modely
import { Pony } from '../../models/Models';

// Markmap
import { Markmap } from 'markmap-view';

// Poznámky
// ------------------------------
// - Knižnica: https://github.com/gera2ld/markmap#readme, nastavenia na konci: https://github.com/gera2ld/markmap/blob/master/packages/markmap-view/src/view.tsx 
// - Pužívam iba časť "markmap-view", ktorá obsluhuje vykreslovanie
// - Vnútorné elementy generujem ručne
// - Pomôcky: 
//   https://www.iamrohit.in/javascript-css-horizontal-employee-treeview/
//   https://markmap.js.org/docs/packages--markmap-view#usage 
//   https://codesandbox.io/examples/package/markmap-view

interface PonyTreeProps {
    pony: Pony;         // Root
    ponies: Pony[];     // All
    levels: number;     // Počet úrovní
}

interface MapNode {
    type?: string;
    depth?: number;
    content?: string;
    color?: string;
    children?: MapNode[];
}

const PonyTree = (props: PonyTreeProps) => {

    // Nastavenia 
    const optionsMap = {
        autoFit: false,
        color: (node: any): string => {
            if ((node?.color?.length ?? 0) > 0) {
                return node.color;
            }
            return 'silver';
        },
        duration: 500,
        embedGlobalCSS: true,
        fitRatio: 0.9,
        maxWidth: 0,
        nodeMinHeight: 16,
        paddingX: 8,
        spacingHorizontal: 60,
        spacingVertical: 20,
        initialExpandLevel: -1,
    };
    const optionsMapNode = {
        colorMale: '#00a6b6',
        colorFemale: '#f52843',
    };

    // Stav
    const refMap = useRef<SVGSVGElement>(null);
    const [mapNodes, setMapNodes] = useState<MapNode>({});

    // Pri každej zmene pregenerujem mapu
    useEffect(() => {
        const localization = Localize();

        // Funkcia pre vygenerovanie html pre vetvu
        const generateContent = (pony: Pony): string => {
            return (
                '<div class="ponytree-node" onclick="window.externalHistoryPush(\'/ponies/?tree=' + (pony.id ?? 0) + '\'); event.preventDefault(); event.stopPropagation();" style="color: ' + (pony.genderMale === true ? optionsMapNode.colorMale : optionsMapNode.colorFemale) + '">' +
                    '<div class="ponytree-node-image" style="' + (pony.fileIsImage ? 'background-image: url(\'' + pony.fileSrcMedium + '\');' : 'background-color: silver;') + ' outline-color: ' + (pony.genderMale === true ? optionsMapNode.colorMale : optionsMapNode.colorFemale) + '"></div>' +
                    '<span class="ponytree-node-title">' +
                        '<span class="ponytree-node-name">' +
                        (pony.name ?? '') +
                        '</span>' +
                        '<span class="ponytree-node-info">' +
                            ((pony.dateOfBirthYear ?? 0) > 100 ? pony.dateOfBirthYear : '-') +
                            ((pony.dateOfBirthLetter?.length ?? 0) > 0 ? ' ' + pony.dateOfBirthLetter : '') + '/' +
                            ((pony.dateOfDeadYear ?? 0) > 100 ? pony.dateOfDeadYear : '-') + ' ' +
                            ((pony.heightAtBirth ?? 0) > 0 ? pony.heightAtBirth : '-') + '/' +
                            ((pony.heightAtFinal ?? 0) > 0 ? pony.heightAtFinal : '-') + ' cm' +
                        '</span>' +
                        '<span class="ponytree-node-edit" onclick="window.externalHistoryPush(\'/ponies/?id=' + (pony.id ?? 0) + '\'); event.preventDefault(); event.stopPropagation();">' + localization.edit + '</span>' +
                    '</span>' +
                '</div>'
            );
        };

        // Funkcia pre rekurzívne vygenerovanie položiek
        const generate = (parentId: number, father: boolean, level?: number): MapNode[] => {
            if (parentId === 0 || (level ?? 0) >= props.levels) {
                return [];
            }
            return props.ponies.filter(item => item['id'] === parentId).map(item => ({
                type: 'headline',
                depth: 4,
                content: generateContent(item),
                color: item.genderMale === true ? optionsMapNode.colorMale : optionsMapNode.colorFemale,
                children: [
                    ...generate(item.fatherPonyId ?? 0, true, (level ?? 0) + 1),
                    ...generate(item.motherPonyId ?? 0, false, (level ?? 0) + 1)
                ]
            }) as MapNode) ?? [];
        };

        // Aktualizujem stav
        setMapNodes({
            type: 'root',
            depth: 4,
            content: generateContent(props.pony),
            color: props.pony.genderMale === true ? optionsMapNode.colorMale : optionsMapNode.colorFemale,
            children: [
                ...generate(props.pony.fatherPonyId ?? 0, true),
                ...generate(props.pony.motherPonyId ?? 0, false)
            ]
        });

    }, [props.ponies, props.pony]);

    // Inicializujem mapu
    useEffect(() => {
        if (refMap.current) {
            refMap.current.innerHTML = "";
            if ((mapNodes.type?.length ?? 0) > 0) {
                Markmap.create(refMap.current, optionsMap, mapNodes);
            }
        }
    }, [mapNodes]);

    return (
        <svg ref={refMap} id="markmap" style={{ width: '100%', height: '100%' }}></svg>
    )
}

export default PonyTree;