import { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { Localize } from '../../AppLocalization';

// Modely
import { Backup } from '../../models/Models';

// Utility
import { FindText } from '../../utility/Search';
import { FormatDecimal } from '../../utility/Format';
import { ColumnsSettings as DataGridColumnSettings, LocalizeGrid } from '../../utility/DataGrid';

// Komponenty
import { DataGrid, GridColDef, GridValueFormatterParams, GridRenderCellParams, GridValueGetterParams, GridFilterModel, GridRowId, GridDensity } from "@mui/x-data-grid";
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Divider, Grid, IconButton, ListItemIcon, Menu, ToggleButton, ToggleButtonGroup, Tooltip, Typography, useTheme } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Search from '../../components/Search';
import { Content, ContentTop, ContentBottom } from '../../layout/Content';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import BackupFileUpload from './BackupFileUpload';

// Ikony
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import GridOnIcon from '@mui/icons-material/GridOn';
import GridViewIcon from '@mui/icons-material/GridView';
import { LoadLocalSettings, SaveLocalSettings } from '../../utility/LocalSettings';
import { FileOpen } from '../../utility/File';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import RestoreIcon from '@mui/icons-material/Restore';
import SaveIcon from '@mui/icons-material/Save';
import { CookieRead } from '../../utility/Cookies';

// Komponent pre zoznam
const Backups = () => {

    // Téma
    const theme = useTheme();

    // Jazyková mutácia
    const localization = Localize();

    // Lokálny stav
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingProcess, setLoadingProcess] = useState<boolean>(false);
    const [search, setSearch] = useState('');
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });

    // Nastavenie zobrazenia    
    const [display, setDisplay] = useState<GridDensity>(LoadLocalSettings('datagrid-display', 'standard') as GridDensity);
    const setDisplayAndSave = (value: string) => {
        setDisplay(value as GridDensity);
        SaveLocalSettings('datagrid-display', value, 'standard');
    };

    // Nastavenia stĺpcov
    const columnsSettings = new DataGridColumnSettings({ uid: 'backups' });
    const columnsDefault: GridColDef[] = [
        { field: 'fileName', headerName: localization.name, hide: false, minWidth: 50, flex: 0.8 },
        {
            field: 'size', headerName: localization.size, hide: false, minWidth: 50, flex: 0.5,
            valueGetter: (params: GridValueGetterParams) => FormatDecimal((params.row.size as number) ?? 0) + ' Kb'
        },
        {
            field: 'createdDate', headerName: localization.created, hide: false, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['createdDate']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? (params?.value as Date).toLocaleDateString() : '-')
        },
        {
            field: 'options', headerName: localization.options, hide: false, width: 300, sortable: false, filterable: false, align: 'right', headerAlign: 'center',
            valueGetter: (params: GridValueGetterParams) => params.row.fileName,
            renderCell: (params: GridRenderCellParams<string>) => (
                <>
                    <Button sx={{ mr: 1 }} variant="contained" size="small" startIcon={<RestoreIcon />} onClick={() => handleRestoreDatabase(params.row.fileName)}>{localization.restore}</Button>
                    <Button sx={{ mr: 1 }} variant="contained" size="small" startIcon={<SaveIcon />} onClick={() => handleExportDatabase(params.row.fileName)} color="secondary">{localization.export}</Button>
                    <IconButton aria-label={localization.delete} title={localization.delete + ' (delete)'} size="small" onClick={() => handleDelete(params.row.fileName)}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </>
            )
        }
    ];

    // Aplikujem uložené nastavenia
    useEffect(() => setColumns(columnsSettings.apply(columns)), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Tabuľka
    const [rows, setRows] = useState<Backup[]>([]);
    const [rowsSelected, setRowsSelected] = useState<GridRowId[]>([])
    const [rowsSelectedMenuEl, setRowsSelectedMenuEl] = useState<null | HTMLElement>(null);
    const [rowsDatabaseMenuEl, setRowsDatabaseMenuEl] = useState<null | HTMLElement>(null);
    const [rowsFiltered, setRowsFiltered] = useState<Backup[]>([]);
    const [columns, setColumns] = useState<GridColDef[]>(columnsDefault);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();

    // Funkcia pre načítanie dát z API
    const loadData = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'backup')
            .then(response => {
                setRows(response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), []);

    // Vyhľadávanie (automaticky odfiltrujem záznamy po zmene hľadaného textu, alebo obnovení riadkov)
    useEffect(() => setRowsFiltered((search.length > 0 ? FindText(rows, search) : rows)), [rows, search]);

    // Vymazať záznam
    const handleDelete = (fileName: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: fileName, children: localization.deleteConfirmation, onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'backup', {
                        params: {
                            'fileName': fileName
                        }
                    })
                    .then(response => {
                        if (response.data === true) {
                            setRows(prev => [...prev.filter(r => r.fileName !== fileName)]);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };
    const handleDeleteList = (fileNames: string[]) => {
        if (fileNames.length === 0) {
            return;
        }
        setConfirm(prev => ({
            ...prev, open: true, title: localization.delete + ': ' + fileNames.length, children: localization.deleteSeletedConfirmation, onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'backup/list', {
                        params: {
                            'fileNames': fileNames
                        }
                    })
                    .then(response => {
                        if (response.data === true) {
                            setRows(prev => [...prev.filter(r => !fileNames.includes(r?.fileName ?? ''))]);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    // Vytvorenie zálohy
    const handleBackupCreate = () => {
        setConfirm(prev => ({
            ...prev, open: true, title: localization.createBackup, children: localization.areYouSure, onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoadingProcess(true);
                axios
                    .post(AppConfig.ApiUri + 'backup')
                    .then(response => {
                        if (response.data === true) {
                            loadData();
                        }
                    })
                    .finally(() => {
                        setLoadingProcess(false);
                    });
            }
        }));
    };

    // Vloženie vlastnej zálohy
    const handleBackupInsert = () => {
        setBackupFileUploadOpen(true);
    };

    // Uloženie vlastnej zálohy (otvorenie adresára)
    // const handleExportOpenFolder = (fileSrc: string) => {
    //     FileOpen(fileSrc + '?action=select', '_blank');
    // };

    // Uloženie vlastnej zálohy (stream cez URL)
    const handleExportDatabase = (fileName: string) => {
        FileOpen(AppConfig.ApiUri+ 'backup/export/?fileName=' + encodeURIComponent(fileName) + '&session=' + CookieRead(AppConfig.CookieName.LoggedSession), '_blank');
    };

    // Obnovenie vybranej databázy
    const handleRestoreDatabase = (fileName: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: localization.restore, children: localization.restoreDatabaseConfirm, onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoadingProcess(true);
                axios
                    .post(AppConfig.ApiUri + 'backup/restore', null, {
                        params: {
                            'fileName': fileName
                        }
                    })
                    .then(response => {
                        if (response.data === true) {
                            loadData();
                        }
                    })
                    .finally(() => {
                        setLoadingProcess(false);
                    });
            }
        }));
    };

    // Vytvorenie prázdnej databázy
    // const handleCreateEmptyDatabase = (useDefault?: boolean) => {
    //     setConfirm(prev => ({
    //         ...prev, open: true, title: localization.createEmptyDatabase, children: localization.createEmptyDatabaseConfirm, onConfirm: () => {
    //             setConfirm(prev => ({ ...prev, open: false }));
    //             setLoadingProcess(true);
    //             axios
    //                 .post(AppConfig.ApiUri + 'backup/empty', null, {
    //                     params: {
    //                         useDefault: useDefault ?? false
    //                     }
    //                 })
    //                 .then(response => {
    //                     if (response.data === true) {
    //                         loadData();
    //                     }
    //                 })
    //                 .finally(() => {
    //                     setLoadingProcess(false);
    //                 });
    //         }
    //     }));
    // };

    const [backupFileUploadOpen, setBackupFileUploadOpen] = useState<boolean>(false);

    return (
        <>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loadingProcess}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Nahrať zálohu */}
            <Dialog keepMounted={false} maxWidth="sm" fullWidth open={backupFileUploadOpen} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { setBackupFileUploadOpen(false); } }}>
                <DialogContent dividers={true}>
                    <Box>
                        <Typography variant="h5" textAlign="center" sx={{ my: 2 }}>{localization.insertBackup} (.zip)</Typography>
                        <BackupFileUpload accept=".zip" maxFileSize={5000} onUploaded={(success) => {
                            setBackupFileUploadOpen(false);
                            if (success) {
                                loadData();
                            }
                        }} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setBackupFileUploadOpen(false)} type="submit" variant="contained">{localization.back}</Button>
                </DialogActions>
            </Dialog>

            {/* Obsah */}
            <Content>
                <ContentTop>
                    <Grid container alignItems="center">

                        {/* Možnosti */}
                        <Grid item xs={9} md lg>

                            {/* Databáza (možnosti) */}
                            <Button variant="contained" sx={{ mr: 1 }} size="large" aria-controls="menu-database" aria-haspopup="true" onClick={(e) => setRowsDatabaseMenuEl(e.currentTarget)} startIcon={<ExpandMoreIcon />}>{localization.database}</Button>
                            <Menu id="menu-database" anchorEl={rowsDatabaseMenuEl} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} sx={{ mt: '55px' }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} open={Boolean(rowsDatabaseMenuEl)} onClose={() => setRowsDatabaseMenuEl(null)} >
                                <MenuItem onClick={() => { handleBackupCreate(); setRowsDatabaseMenuEl(null); }}>
                                    <ListItemIcon><FolderZipIcon fontSize="small" /></ListItemIcon> {localization.createBackup}
                                </MenuItem>
                                <MenuItem onClick={() => { handleBackupInsert(); setRowsDatabaseMenuEl(null); }}>
                                    <ListItemIcon><DriveFolderUploadIcon fontSize="small" /></ListItemIcon> {localization.insertBackup}
                                </MenuItem>
                                {/* <Divider />
                                <MenuItem onClick={() => { handleCreateEmptyDatabase(); setRowsDatabaseMenuEl(null); }}>
                                    <ListItemIcon><CreateNewFolderIcon fontSize="small" /></ListItemIcon> {localization.createEmptyDatabase}
                                </MenuItem>
                                <MenuItem onClick={() => { handleCreateEmptyDatabase(true); setRowsDatabaseMenuEl(null); }}>
                                    <ListItemIcon><CreateNewFolderIcon fontSize="small" /></ListItemIcon> {localization.createEmptyDatabase + ' (' + localization.defaultSettings.toLowerCase() + ')'}
                                </MenuItem> */}
                            </Menu>

                            {/* Označené záznamy (možnosti) */}
                            <Button variant="text" size="large" disabled={rowsSelected?.length === 0} aria-controls="menu-selected" aria-haspopup="true" onClick={(e) => setRowsSelectedMenuEl(e.currentTarget)} endIcon={<ExpandMoreIcon />}>{localization.selected} {'(' + rowsSelected.length.toString() + ')'}</Button>
                            <Menu id="menu-selected" anchorEl={rowsSelectedMenuEl} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} sx={{ mt: '55px' }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} open={Boolean(rowsSelectedMenuEl)} onClose={() => setRowsSelectedMenuEl(null)} >
                                <MenuItem onClick={() => { handleDeleteList(rowsSelected.map(r => r as string)); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon> {localization.delete}
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { setRowsSelected([]); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><ClearIcon fontSize="small" /></ListItemIcon> {localization.cancelSelection}
                                </MenuItem>
                            </Menu>
                        </Grid>

                        {/* Zobrazenie */}
                        <Grid item xs md={2} textAlign="right">
                            <ToggleButtonGroup exclusive value={display} size="small" sx={{ mr: { xs: 0, md: 1 }, backgroundBackup: theme.layout.content.backgroundColor }} onChange={(e: any, d) => { if (d !== null) { setDisplayAndSave(d); } }}>
                                <ToggleButton value="compact" aria-label={localization.compact}>
                                    <Tooltip title={localization.compact}>
                                        <GridOnIcon />
                                    </Tooltip>
                                </ToggleButton>
                                <ToggleButton value="standard" aria-label={localization.normal}>
                                    <Tooltip title={localization.normal}>
                                        <GridViewIcon />
                                    </Tooltip>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>

                        {/* Vyhľadávanie */}
                        <Grid item xs={12} md={4} sx={{ mt: { xs: 1, md: 0 } }}>
                            <Search onSearch={s => setSearch(s)}
                                onClear={() => {
                                    setSearch('');
                                    setFilterModel({ items: [], linkOperator: undefined });
                                }}
                                autoFocus={true} />
                        </Grid>
                    </Grid>
                </ContentTop>
                <ContentBottom>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <DataGrid
                            getRowId={row => row.fileName}
                            density={display}
                            checkboxSelection
                            disableSelectionOnClick
                            columns={columns}
                            rows={rowsFiltered}
                            localeText={LocalizeGrid()}
                            loading={loading}

                            disableVirtualization

                            // Dvoj-klik (úprava)
                            onCellDoubleClick={(e) => {
                                handleExportDatabase(e.row.fileName);
                            }}

                            // Klávesnica (shift+enter => upraviť, shift+delete => vymazať, shift+space => označiť, vstavaná funkcia)
                            onCellKeyDown={(e, c) => {
                                if (c.code === 'Enter' && c.ctrlKey) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    handleExportDatabase(e.row.fileName);
                                    return;
                                }
                                if (c.code === 'Delete' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    handleDelete(e.row.fileName);
                                    return;
                                }
                            }}

                            // Filtrácia
                            filterModel={filterModel}
                            onFilterModelChange={e => setFilterModel(e)}

                            // Vybrané záznamy
                            selectionModel={rowsSelected}
                            onSelectionModelChange={e => setRowsSelected(e)}

                            // Stĺpce (automatické ukladanie nastavení)
                            onColumnVisibilityChange={e => columnsSettings.columnVisibilityChanged(e, columnsDefault)}
                        />
                    </div>
                </ContentBottom>
            </Content>

        </>
    )
}

export default Backups;