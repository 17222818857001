import { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { Localize } from '../../AppLocalization';

// Utility
import Debounce from '../../utility/Debounce';

// Komponenty
import { Backdrop, Box, CircularProgress, Grid, Paper, Slider, Typography } from '@mui/material';
import { Content, ContentTop, ContentBottom } from '../../layout/Content';

// Graf
import { Bar, Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, LineController, BarController, } from 'chart.js';
import { StatisticsData } from '../../models/Models';
ChartJS.register(ArcElement, LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, LineController, BarController);

interface StatisticsProps {

}

const Statistics = (props: StatisticsProps) => {

    // Jazyková mutácia
    const localization = Localize();

    // Stav
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<StatisticsData>({});

    // Údaje pre filter
    const [filterYear, setfilterYear] = useState<number[]>([1940, new Date().getFullYear()]);
    const filterYearRange: number[] = [1940, new Date().getFullYear()]
    const filterYearLabels: number[] = [];

    for (var i = filterYearRange[0]; i <= filterYearRange[1]; i++) {
        if ((i % 5) === 0) {
            filterYearLabels.push(i);
        }
    }

    // Funkcie pre automatický filter po zmene
    const handleFilter = Debounce(() => { handleFilterStart(); }, 500);
    const handleFilterStart = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'pony/statistics', {
                params: {
                    'yearFrom': filterYear[0],
                    'yearTo': filterYear[1],
                    'labelMale': localization.stallion,
                    'labelFemale': localization.aMare,
                }
            })
            .then(response => {
                setData(response.data as StatisticsData);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => { handleFilter(); }, [filterYear]);

    const chartDognutOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: { position: 'left' as const, },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        return context.label || context.parsed || '';
                    }
                }
            }
        },
        borderWidth: 1,
    };
    const chartBarOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: { position: 'top' as const, },
        },
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        scales: {
            x: { stacked: true, },
            y: { stacked: true, },
        },
        scale: {
            ticks: {
                precision: 0
            }
        }
    };

    // Pekné:   https://demos.creative-tim.com/otis-admin-pro/#/pages/charts
    // Docs:    https://react-chartjs-2.js.org/examples/line-chart

    const GetData = (index: number): any => {
        return ({
            labels: [...(data?.charts !== undefined ? data?.charts[index]?.labels ?? [] : [])],
            datasets: [...(data?.charts !== undefined ? data?.charts[index]?.datasets ?? [] : [])],
        });
    }

    return (
        <>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Content>
                <ContentTop>
                    <Grid container alignItems="center">
                        <Grid item xs={12} px={4} pt={2}>
                            <Slider
                                size="small"
                                min={filterYearRange[0]}
                                max={filterYearRange[1]}
                                step={1}
                                sx={{ mt: -1 }}
                                value={[filterYear[0], filterYear[1]]}
                                onChange={(event, value) => {
                                    if (typeof value !== 'number') {
                                        setfilterYear(value);
                                    }
                                }}
                                valueLabelDisplay="on"
                                marks={filterYearLabels.map(year => ({ value: year, label: year }))}
                                valueLabelFormat={(value: number) => `${value}`}
                            />
                        </Grid>
                    </Grid>
                </ContentTop>
                <ContentBottom>
                    <Box sx={{ display: 'block', position: 'relative', width: '100%', height: '100%' }}>
                        <Box sx={{ display: 'block', position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', overflowY: 'auto' }}>

                            <Paper variant="outlined" sx={{ mb: 2, p: 2, pb: 4 }}>
                                <Grid container>
                                    <Grid item xs={6} lg={3}>
                                        <Typography variant="h5" textAlign="center" sx={{ my: 2 }}>{localization.numberOfBirths}</Typography>
                                        <Box sx={{ height: '200px' }}><Doughnut data={GetData(0)} options={chartDognutOptions} /></Box>
                                    </Grid>
                                    <Grid item xs={6} lg={3}>
                                        <Typography variant="h5" textAlign="center" sx={{ my: 2 }}>{localization.numberOfDeads}</Typography>
                                        <Box sx={{ height: '200px' }}><Doughnut data={GetData(1)} options={chartDognutOptions} /></Box>
                                    </Grid>
                                    <Grid item xs={6} lg={3}>
                                        <Typography variant="h5" textAlign="center" sx={{ my: 2 }}>{localization.conditions}</Typography>
                                        <Box sx={{ height: '200px' }}><Doughnut data={GetData(2)} options={chartDognutOptions} /></Box>
                                    </Grid>
                                    <Grid item xs={6} lg={3}>
                                        <Typography variant="h5" textAlign="center" sx={{ my: 2 }}>{localization.trophiesAndTitles}</Typography>
                                        <Box sx={{ height: '200px' }}><Doughnut data={GetData(3)} options={chartDognutOptions} /></Box>
                                    </Grid>
                                </Grid>
                            </Paper>

                            <Paper variant="outlined" sx={{ mb: 2, p: 2 }}>
                                <Typography variant="h5" textAlign="center" sx={{ my: 2 }}>{localization.numberOfBirths}</Typography>
                                <Box sx={{ height: '300px' }}>
                                    <Bar data={GetData(4)} options={chartBarOptions} />
                                </Box>
                            </Paper>

                            <Paper variant="outlined" sx={{ mb: 2, p: 2 }}>
                                <Typography variant="h5" textAlign="center" sx={{ my: 2 }}>{localization.numberOfDeads}</Typography>
                                <Box sx={{ height: '300px' }}>
                                    <Bar data={GetData(5)} options={chartBarOptions} />
                                </Box>
                            </Paper>

                            {data.charts?.map((ds, index) => ds.datatype === 'sold' ? (
                                <Paper key={index} variant="outlined" sx={{ p: 2 }}>
                                    <Typography variant="h5" textAlign="center" sx={{ my: 2 }}>{localization.numberOfSold} / {ds.dataname}</Typography>
                                    <Box sx={{ height: '300px' }}>
                                        <Bar data={GetData(index)} options={chartBarOptions} />
                                    </Box>
                                </Paper>
                            ) : null)}

                        </Box>
                    </Box>
                </ContentBottom>
            </Content>
        </>
    )
}

export default Statistics;