import { useState } from 'react'
import { Localize } from '../../AppLocalization';

// Utility
import { FileOpen } from '../../utility/File';

// Modely
import { PonyFile } from '../../models/Models';
import { PonyCreateComponentProps } from './PonyCreate';

// Komponenty
import { Card, CardMedia, MenuItem, FormControlLabel, Switch, CardActionArea, CardActions, CardContent, IconButton, Typography, Divider, ListItemIcon, Menu } from '@mui/material';
import Grid from '@mui/material/Grid';
import PonyFileUpload from './PonyFileUpload';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import PonyCreateFilesEdit, { PonyCreateFilesEditProps } from './PonyCreateFilesEdit';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';


interface PonyCreateFilesProps extends PonyCreateComponentProps {

}

const PonyCreateFiles = (props: PonyCreateFilesProps) => {

    // Jazyková mutácia
    const localization = Localize();

    // Kontextové menu
    const [filesMenuItem, setFilesMenuItem] = useState<PonyFile>();
    const [filesMenuEl, setFilesMenuEl] = useState<null | HTMLElement>(null);

    // Stav
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [ponyCreateFilesEdit, setPonyCreateFilesEdit] = useState<PonyCreateFilesEditProps>({
        open: false,
        keepMounted: true,
        file: {},
        onSave: (file: PonyFile) => {
            props.setSource(prev => {
                const changed = [...prev?.files ?? []];
                changed?.every((item, itemIndex) => {
                    if (item.fileName === file.fileName) {
                        changed[itemIndex] = file;
                        return false;
                    }
                    return true;
                });
                return { ...prev, files: changed };
            });
        },
        onClose: () => handleFilesEditClose()
    });
    const handleFilesEditOpen = (file: PonyFile) => {
        setPonyCreateFilesEdit(prev => ({ ...prev, open: true, file: file }));
    };
    const handleFilesEditClose = () => {
        setPonyCreateFilesEdit(prev => ({ ...prev, open: false }));
    };

    // Funkcia pre otvorenie kontexového menu zložky
    const handleFilesMenuOpen = (e: HTMLElement, file: PonyFile) => {
        setFilesMenuItem(file);
        setFilesMenuEl(e);
    };

    // Po dvoj-kliku otvorím súbor
    const handleFileClick = (file: PonyFile) => {
        if (file?.fileSrc !== undefined) {
            FileOpen(file?.fileSrc, '_blank');
        }
    };

    // Vymazať súbor 
    const handleFileDelete = (file: PonyFile) => {
        setConfirm(prev => ({
            ...prev, open: true, title: localization.file, children: localization.deleteConfirmation, onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                props.setSource(prev => {
                    let changed = (prev.files ?? [])?.filter(item => !(item.fileName === file.fileName));
                    return { ...prev, files: changed };
                });
            }
        }));
    }

    // Upraviť súbor
    const handleFileChange = (fileName: string, property: string, value: any) => {
        props.setSource(prev => {
            const changed = [...prev?.files ?? []];

            // Získam index položky
            let index = 0;
            changed?.every((item, itemIndex) => {
                if (item.fileName === fileName) {
                    index = itemIndex;
                    return false;
                }
                return true;
            });

            // Upravím položku
            changed[index] = { ...changed[index], [property]: value };

            // Prepínač - ak som označil obrázok ako titulný v detaile alebo v zozname, tak všetky ostatné odznačím
            if (property === 'cover') {
                changed?.every((item, itemIndex) => {
                    if (itemIndex !== index) {
                        changed[itemIndex] = { ...changed[itemIndex], [property]: false };
                    }
                    return true;
                });
            }

            return { ...prev, files: changed };
        });
    };

    // Vložiť nahrané súbory
    const handleFileUpload = (files: PonyFile[]) => {
        if (files.length === 0) {
            return;
        }
        props.setSource(prev => {
            let changed = prev.files ?? [];
            files.forEach(file => {
                const ordinalNumber = ((changed?.length ?? 0) > 0 ? (Math.max(...(changed?.map(i => i.ordinalNumber ?? 0) ?? [])) ?? 0) + 1 : 0);
                changed.push({ ...file, ordinalNumber: ordinalNumber, cover: file.fileIsImage && (changed.filter(f => f.cover).length ?? 0) === 0 });
            });
            return { ...prev, files: changed };
        });
    };

    // Zoradenie položiek, najskôr podľa poradia potom podľa id súboru (poradie môže byť rovnaké)
    const filesSortComparer = (a: PonyFile, b: PonyFile): number => {
        if ((a.ordinalNumber ?? 0) !== (b.ordinalNumber ?? 0)) {
            return (a.ordinalNumber ?? 0) > (b.ordinalNumber ?? 0) ? 1 : -1;
        }
        else {
            return (a.fileName ?? 0) > (b.fileName ?? 0) ? 1 : -1;
        }
    }

    return (
        <>
            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            <Grid container columnSpacing={1}>

                {/* Nahrať súbory */}
                {props.readonly === false && (
                    <Grid item xs={12} mb={1}>
                        <PonyFileUpload onUploaded={handleFileUpload} />
                    </Grid>
                )}

                {/* Kontextové menu položky */}
                <Menu id="menu-directories" anchorEl={filesMenuEl} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} open={Boolean(filesMenuEl)} onClose={() => setFilesMenuEl(null)} >
                    <MenuItem onClick={() => { if (filesMenuItem !== undefined && filesMenuItem?.fileSrc !== undefined) { FileOpen(filesMenuItem?.fileSrc, '_blank'); } setFilesMenuEl(null); }}>
                        <ListItemIcon><SearchIcon fontSize="small" /></ListItemIcon> {localization.open}
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => { handleFilesEditOpen(filesMenuItem ?? {}); setFilesMenuEl(null); }}>
                        <ListItemIcon><EditIcon fontSize="small" /></ListItemIcon> {localization.edit}
                    </MenuItem>
                    <MenuItem onClick={() => { handleFileDelete(filesMenuItem ?? {}); setFilesMenuEl(null); }}>
                        <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon> {localization.delete}
                    </MenuItem>
                </Menu>

                {/* Zoznam */}
                <Grid mt={1} item container xs={12} columnSpacing={2} rowSpacing={2} {...(props.source.files?.length === 0 && { display: "none" })}>
                    {props.source.files?.sort((a, b) => filesSortComparer(a, b))?.map((file) => (
                        <Grid key={file.fileName} item xs={4} sm={3}>
                            <Card>
                                <CardActionArea onClick={() => handleFileClick(file)}>
                                    {file?.fileIsImage ? (
                                        <CardMedia component="img" image={(file?.fileSrcMedium ?? '')} alt={file.note ?? ''} sx={{ height: { xs: '100px', md: '150px' } }} />
                                    ) : (
                                        <CardMedia component="div" sx={{ display: 'flex', height: { xs: '100px', md: '150px' }, justifyContent: 'center', alignItems: 'center' }}>
                                            <InsertDriveFileOutlinedIcon fontSize="large" />
                                        </CardMedia>
                                    )}
                                </CardActionArea>
                                <CardContent sx={{ pb: 0, pt: 1 }}>
                                    {props.readonly === false && (
                                        <FormControlLabel disabled={props.readonly} control={<Switch checked={file.cover ?? false} size="small" disabled={!file.fileIsImage} name="cover" onChange={(e) => { handleFileChange(file.fileName ?? '', e.target.name, e.target.checked) }} />} label={<Typography noWrap fontSize="small">{localization.coverImage}</Typography>} />
                                    )}
                                </CardContent>

                                <CardActions sx={{ pt: 0, pb: 1 }}>
                                    <Typography noWrap fontSize="small" pl={1}>{'#' + (file.ordinalNumber ?? 0) + ' - ' + ((file.note?.length ?? 0) > 0 ? file.note : '...')}</Typography>
                                    {props.readonly === false && (
                                        <IconButton sx={{ marginLeft: 'auto' }} onClick={(e) => handleFilesMenuOpen(e.currentTarget, file)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    )}
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Grid>

            {/* Upraviť súbor */}
            <PonyCreateFilesEdit {...ponyCreateFilesEdit} />
        </>
    )
}

export default PonyCreateFiles;