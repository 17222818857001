import React, { useState } from 'react';
import { Localize } from '../../AppLocalization';

// Modely
import { Trophy, PonyTrophy } from '../../models/Models';
import { PonyCreateComponentProps } from './PonyCreate';
import PonyCreateTrophy, { PonyCreateTrophyProps } from './PonyCreateTrophy';

// Utility
import { format as dateFormat } from 'date-fns';

// Komponenty
import { Alert, AlertTitle, Button, Grid, IconButton } from '@mui/material';
import Confirm, { ConfirmProps } from '../../components/Confirm';

// Ikony
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

// Vstupné parametre
export interface PonyCreateTrophiesProps extends PonyCreateComponentProps {
    trophies: Trophy[];
}

const PonyCreateTrophies = (props: PonyCreateTrophiesProps) => {

    // Jazyková mutácia
    const localization = Localize();

    // Lokálny stav
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });

    // Stav (editácia položky)
    const [orderCreatePayment, setPonyCreateTrophy] = useState<PonyCreateTrophyProps>({
        open: false,
        trophies: [],
        onSave: (trophy, trophyIndex) => {
            props.setSource(prev => {
                let trophies: PonyTrophy[] = [];

                // Aktualizujem alebo vytvorím záznam
                if (trophyIndex !== undefined && trophyIndex >= 0) {
                    trophies = [...prev.trophies ?? []];
                    trophies[trophyIndex] = { ...trophy };
                }
                else {
                    trophies = [...prev.trophies ?? [], trophy];
                }

                // Vrátim aktualizované záznamy
                return ({ ...prev, trophies: trophies });
            });
        },
        onClose: () => setPonyCreateTrophy(prev => ({ ...prev, open: false }))
    });

    // Pridať upraviť záznam
    const handleCreate = (index?: number) => {
        let trophy: PonyTrophy | undefined = undefined;

        // Iba na čítanie
        if (props.readonly) {
            return;
        }

        // Úprava položky podľa indexu
        if (index !== undefined && index >= 0) {
            trophy = (props.source?.trophies ?? [])[index ?? 0];
        }

        setPonyCreateTrophy(prev => ({
            ...prev,
            trophy: trophy,
            trophyIndex: index,
            open: true
        }));
    };

    // Vymazať záznam
    const handleDelete = (index: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: localization.deleteConfirmation, onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                props.setSource(prev => {
                    const trophies = prev.trophies ?? [];
                    return ({ ...prev, trophies: [...trophies.filter(trophy => trophy !== trophies[index]) ?? []] });
                });
            }
        }));
    };

    return (
        <>

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Nový záznam */}
            <PonyCreateTrophy {...orderCreatePayment} trophies={props.trophies} />

            <Grid container columnSpacing={1}>

                {props.readonly === false && (
                    <Grid item xs={12} mb={1}>
                        <Button variant="contained" size="small" startIcon={<AddIcon />} color="secondary" sx={{ mr: 1 }} aria-label={localization.newRecord} aria-controls="menu-add" aria-haspopup="true" onClick={() => handleCreate()}>{localization.newRecord}</Button>
                    </Grid>
                )}

                {/* Zoznam */}
                {
                    props.source.trophies?.map((trophy, index) => {
                        return (
                            <Grid item xs={12} mt={1} key={index}>
                                <Alert
                                    variant="standard"
                                    severity="info"
                                    icon={<EmojiEventsIcon />}
                                    onDoubleClick={() => handleCreate(index)}
                                    action={
                                        <>
                                            {props.readonly === false && (<>
                                                <IconButton aria-label={localization.edit} color="inherit" size="small" onClick={() => handleCreate(index)}>
                                                    <EditIcon fontSize="inherit" />
                                                </IconButton>
                                                <IconButton aria-label={localization.delete} color="inherit" size="small" onClick={() => handleDelete(index, localization.trophy)}>
                                                    <DeleteIcon fontSize="inherit" />
                                                </IconButton>
                                            </>)}
                                        </>
                                    }>
                                    <AlertTitle>
                                        <strong>{(trophy.name?.length ?? 0) > 0 && trophy.name + ' / '} {props.trophies.find(ex => ex.id === trophy.trophyId)?.name ?? ''}</strong>
                                    </AlertTitle>
                                    {dateFormat(new Date(trophy.date ?? new Date()), 'dd.MM.yyyy')}
                                    {(trophy.note?.length ?? 0) > 0 ? <><br />{trophy.note}</> : null}
                                </Alert>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    )
}

export default PonyCreateTrophies;