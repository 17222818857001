import { Alert, AlertColor, Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import axios from 'axios';
import { useState, FormEvent } from 'react'
import { AppConfig } from '../../AppConfig';
import { GetLanguage, Localize } from '../../AppLocalization';

interface Props {
    onClickToggle: () => void;
}

const PasswordRecovery = (props: Props) => {

    // Jazyková mutácia
    const localization = Localize();

    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [alert, setAlert] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('warning');

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        const email = data.get('email')?.toString() ?? '';
        const emailIsValid = /\S+@\S+\.\S+/.test(email);    

        setEmailError(!emailIsValid);
        
        if(!emailIsValid) {
            setAlertSeverity('error');
            setAlert(localization.mustFill + ': ' + localization.email + '!');
            return;
        }
        setLoading(true);
        axios
            .post(AppConfig.ApiUri + 'user/passwordrecovery', null, { params: {
                'email': email,
                'language': GetLanguage()
            }})
            .then(response => {
                if(response.data === 'success') {
                    setAlertSeverity('success');
                    setAlert(localization.emailSent);
                    (event.target as HTMLFormElement).reset();
                } else {
                    setAlertSeverity('error');
                    setAlert(localization.emailNotSent);
                }
            })
            .finally(() => {
                setLoading(false);
            }); 
    };

    return (
        <>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog maxWidth="sm" fullWidth open={true} onClose={props.onClickToggle}>
                <Box component="form" onSubmit={handleSubmit} noValidate>
                <DialogTitle>{localization.forgotPassword}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {localization.forgotPasswordConfirmSent}
                    </DialogContentText>
                    <TextField autoFocus sx={{ mt: 3 }} name="email" type="email" error={emailError} required label={localization.email} fullWidth variant="standard" autoComplete="email" />
                    {(alert.length > 0 && <Alert sx={{ mt: 1 }} severity={ alertSeverity as AlertColor }>{alert}</Alert>)}  
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClickToggle}>{localization.back}</Button>
                    <Button type="submit" variant="contained">{localization.send}</Button>
                </DialogActions>
            </Box>
            </Dialog>
        </>
    )
}

export default PasswordRecovery;