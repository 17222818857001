
// Vráti hodnotu null ak je dátum menší alebo rovný ako 01.01.1900
export const nullMinDate = (date?: Date, minYear?: number): Date | null => {
    if (date === undefined || date === null || isNaN(Date.parse(date?.toString() ?? ''))) {
        return null;
    }
    try {
        if (new Date(date).getFullYear() <= (minYear ?? 1900)) {
            return null;
        }
    } catch { return null; }
    return date;
};
