import React, { useEffect, useState } from 'react'
import { Localize } from '../../AppLocalization';

// Modely
import { Pony } from '../../models/Models';
import { PonyCreateComponentProps } from './PonyCreate';

// Komponenty
import { TextField, FormControl, Autocomplete, CircularProgress, Popper } from '@mui/material';
import Grid from '@mui/material/Grid';

interface PonyCreateFamilyTreeProps extends PonyCreateComponentProps {
    ponies: Pony[];
}

const PonyCreateFamilyTree = (props: PonyCreateFamilyTreeProps) => {

    // Jazyková mutácia
    const localization = Localize();

    return (
        <>
            <Grid container columnSpacing={1}>
                <Grid item xs={6}>
                    <PonyCreateFamilyTreeParent
                        readonly={props.readonly}
                        label={localization.father}
                        parentPonyId={props.source.fatherPonyId}
                        parentPonyName={props.source.fatherPonyName}
                        ponies={props.ponies.filter(pony => pony.id !== props.source.id && pony.genderMale === true)}
                        onChange={(id, name) => {
                            props.setSource(prev => ({
                                ...prev,
                                'fatherPonyId': id,
                                'fatherPonyName': name
                            }));
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <PonyCreateFamilyTreeParent
                        readonly={props.readonly}
                        label={localization.mother}
                        parentPonyId={props.source.motherPonyId}
                        parentPonyName={props.source.motherPonyName}
                        ponies={props.ponies.filter(pony => pony.id !== props.source.id && pony.genderMale === false)}
                        onChange={(id, name) => {
                            props.setSource(prev => ({
                                ...prev,
                                'motherPonyId': id,
                                'motherPonyName': name
                            }));
                        }}
                    />
                </Grid>
            </Grid>
        </>
    )
}
export default PonyCreateFamilyTree;

// Predpis pre autocomplete
interface AutocompleteOption {
    id: number;
    name: string;
}

interface PonyCreateFamilyTreeParentProps {
    label?: string;
    parentPonyId?: number;
    parentPonyName?: string;
    ponies: Pony[];
    readonly: boolean;
    onChange: (id?: number, name?: string) => void;
}

const PonyCreateFamilyTreeParent = (props: PonyCreateFamilyTreeParentProps) => {

    // Rodičia - autocomplete
    const [parentsOpen, setParentsOpen] = useState<boolean>(false);
    const [parentsOptions, setParentsOptions] = useState<AutocompleteOption[]>([]);
    const parentsLoading = parentsOpen && parentsOptions.length === 0;

    // Vynulujem po zavrení
    useEffect(() => {
        if (!parentsOpen) {
            setParentsOptions([]);
        }
    }, [parentsOpen]);

    // Zoradenie položiek, najskôr podľa poradia potom podľa id súboru (poradie môže byť rovnaké)
    const optionsSortComparer = (a: AutocompleteOption, b: AutocompleteOption): number => {
        if ((a.name ?? 0) !== (b.name ?? 0)) {
            return (a.name ?? 0) > (b.name ?? 0) ? 1 : -1;
        }
        else {
            return (a.id ?? 0) > (b.id ?? 0) ? 1 : -1;
        }
    }

    // Naplním po otvorení
    useEffect(() => {
        if (!parentsLoading) {
            return;
        }
        let options: AutocompleteOption[] = [];
        props.ponies.forEach(pony => {
            let name = pony.name + ((pony.dateOfBirthYear ?? 0) > 100 ? ' ' + pony.dateOfBirthYear + ' ' + pony.dateOfBirthLetter : '');
            let surfix = '';
            let counter = 0;
            let next = false;

            // Každý názov musí byť unikátny (surfix prikladám až od č. 1)
            while (!next && counter < 100) {
                if (counter > 0) {
                    surfix = ' #' + counter;
                }
                next = (options.find(o => o.name === name + surfix) === undefined);
                counter++;
            }

            // Vložím len ak sa podarilo nájsť unikátny názov
            if (next) {
                options.push(({ 'id': pony.id, 'name': name + surfix }) as AutocompleteOption);
            }
        });

        // Všetky možnosťi musia byť názvom unikátne
        setParentsOptions(options.sort((a, b) => optionsSortComparer(a, b)));
    }, [parentsLoading]);

    return (
        <>
            <FormControl margin="dense" variant="outlined" fullWidth>
                <Autocomplete
                    disabled={props.readonly}
                    id="filter-parents"
                    open={parentsOpen}
                    value={((props.parentPonyId ?? 0) > 0 ? { 'id': props.parentPonyId, 'name': props.parentPonyName ?? '' } : parentsOpen ? null : { 'id': 0, 'name': '-' })}
                    onChange={(s: object, e: any) => {
                        props.onChange((e != null ? (e as AutocompleteOption).id : undefined), (e != null ? (e as AutocompleteOption).name : undefined));
                    }}
                    onOpen={() => { setParentsOpen(true); }}
                    onClose={() => { setParentsOpen(false); }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.name}
                    options={parentsOptions}
                    loading={parentsLoading}
                    renderInput={(params) => (
                        <TextField {...params} label={props.label} InputProps={{
                            ...params.InputProps, endAdornment: (
                                <React.Fragment>
                                    {parentsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                        />
                    )}
                    PopperComponent={(props) => <Popper {...props} style={{ maxWidth: "fit-content", minWidth: "250px" }} />}
                />
            </FormControl>
        </>
    )
}