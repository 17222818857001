import React, { useState } from 'react';
import { Localize } from '../../AppLocalization';

// Modely
import { Examination, PonyExamination } from '../../models/Models';
import { PonyCreateComponentProps } from './PonyCreate';
import PonyCreateExamination, { PonyCreateExaminationProps } from './PonyCreateExamination';

// Utility
import { format as dateFormat } from 'date-fns';

// Komponenty
import { Alert, AlertTitle, Button, Grid, IconButton } from '@mui/material';
import Confirm, { ConfirmProps } from '../../components/Confirm';

// Ikony
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';

// Vstupné parametre
export interface PonyCreateExaminationsProps extends PonyCreateComponentProps {
    examinations: Examination[];
}

const PonyCreateExaminations = (props: PonyCreateExaminationsProps) => {

    // Jazyková mutácia
    const localization = Localize();

    // Lokálny stav
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });

    // Stav (editácia položky)
    const [orderCreatePayment, setPonyCreateExamination] = useState<PonyCreateExaminationProps>({
        open: false,
        examinations: [],
        onSave: (examination, examinationIndex) => {
            props.setSource(prev => {
                let examinations: PonyExamination[] = [];

                // Aktualizujem alebo vytvorím záznam
                if (examinationIndex !== undefined && examinationIndex >= 0) {
                    examinations = [...prev.examinations ?? []];
                    examinations[examinationIndex] = { ...examination };
                }
                else {
                    examinations = [...prev.examinations ?? [], examination];
                }

                // Vrátim aktualizované záznamy
                return ({ ...prev, examinations: examinations });
            });
        },
        onClose: () => setPonyCreateExamination(prev => ({ ...prev, open: false }))
    });

    // Pridať upraviť záznam
    const handleCreate = (index?: number) => {
        let examination: PonyExamination | undefined = undefined;

        // Iba na čítanie
        if (props.readonly) {
            return;
        }

        // Úprava položky podľa indexu
        if (index !== undefined && index >= 0) {
            examination = (props.source?.examinations ?? [])[index ?? 0];
        }

        setPonyCreateExamination(prev => ({
            ...prev,
            examination: examination,
            examinationIndex: index,
            open: true
        }));
    };

    // Vymazať záznam
    const handleDelete = (index: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: localization.deleteConfirmation, onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                props.setSource(prev => {
                    const examinations = prev.examinations ?? [];
                    return ({ ...prev, examinations: [...examinations.filter(examination => examination !== examinations[index]) ?? []] });
                });
            }
        }));
    };

    return (
        <>

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Nový záznam */}
            <PonyCreateExamination {...orderCreatePayment} examinations={props.examinations} />

            <Grid container columnSpacing={1}>

                {props.readonly === false && (
                    <Grid item xs={12} mb={1}>
                        <Button variant="contained" size="small" startIcon={<AddIcon />} color="secondary" sx={{ mr: 1 }} aria-label={localization.newRecord} aria-controls="menu-add" aria-haspopup="true" onClick={() => handleCreate()}>{localization.newRecord}</Button>
                    </Grid>
                )}

                {/* Zoznam */}
                {
                    props.source.examinations?.map((examination, index) => {
                        return (
                            <Grid item xs={12} mt={1} key={index}>
                                <Alert
                                    variant="standard"
                                    severity="info"
                                    icon={<HealthAndSafetyIcon />}
                                    onDoubleClick={() => handleCreate(index)}
                                    action={
                                        <>
                                            {props.readonly === false && (
                                                <>
                                                    <IconButton aria-label={localization.edit} color="inherit" size="small" onClick={() => handleCreate(index)}>
                                                        <EditIcon fontSize="inherit" />
                                                    </IconButton>
                                                    <IconButton aria-label={localization.delete} color="inherit" size="small" onClick={() => handleDelete(index, localization.examination)}>
                                                        <DeleteIcon fontSize="inherit" />
                                                    </IconButton>
                                                </>
                                            )}
                                        </>
                                    }>
                                    <AlertTitle>
                                        <strong>{props.examinations.find(ex => ex.id === examination.examinationId)?.name ?? ''}</strong>
                                    </AlertTitle>
                                    {dateFormat(new Date(examination.date ?? new Date()), 'dd.MM.yyyy')}
                                    {(examination.note?.length ?? 0) > 0 ? <><br />{examination.note}</> : null}
                                </Alert>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    )
}

export default PonyCreateExaminations;