import React, { useState } from 'react';
import { Localize } from '../../AppLocalization';

// Modely
import { PonyNote } from '../../models/Models';
import { PonyCreateComponentProps } from './PonyCreate';
import PonyCreateNote, { PonyCreateNoteProps } from './PonyCreateNote';

// Utility
import { format as dateFormat } from 'date-fns';

// Komponenty
import { Alert, AlertTitle, Button, Grid, IconButton } from '@mui/material';
import Confirm, { ConfirmProps } from '../../components/Confirm';

// Ikony
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';

// Vstupné parametre
export interface PonyCreateNotesProps extends PonyCreateComponentProps {
}

const PonyCreateNotes = (props: PonyCreateNotesProps) => {

    // Jazyková mutácia
    const localization = Localize();

    // Lokálny stav
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });

    // Stav (editácia položky)
    const [orderCreatePayment, setPonyCreateNote] = useState<PonyCreateNoteProps>({
        open: false,
        onSave: (note, noteIndex) => {
            props.setSource(prev => {
                let notes: PonyNote[] = [];

                // Aktualizujem alebo vytvorím záznam
                if (noteIndex !== undefined && noteIndex >= 0) {
                    notes = [...prev.notes ?? []];
                    notes[noteIndex] = { ...note };
                }
                else {
                    notes = [...prev.notes ?? [], note];
                }

                // Vrátim aktualizované záznamy
                return ({ ...prev, notes: notes });
            });
        },
        onClose: () => setPonyCreateNote(prev => ({ ...prev, open: false }))
    });

    // Pridať upraviť záznam
    const handleCreate = (index?: number) => {
        let note: PonyNote | undefined = undefined;

        // Iba na čítanie
        if (props.readonly) {
            return;
        }

        // Úprava položky podľa indexu
        if (index !== undefined && index >= 0) {
            note = (props.source?.notes ?? [])[index ?? 0];
        }

        setPonyCreateNote(prev => ({
            ...prev,
            note: note,
            noteIndex: index,
            open: true
        }));
    };

    // Vymazať záznam
    const handleDelete = (index: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: localization.deleteConfirmation, onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                props.setSource(prev => {
                    const notes = prev.notes ?? [];
                    return ({ ...prev, notes: [...notes.filter(note => note !== notes[index]) ?? []] });
                });
            }
        }));
    };

    return (
        <>

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Nový záznam */}
            <PonyCreateNote {...orderCreatePayment} />

            <Grid container columnSpacing={1}>

                {props.readonly === false && (
                    <Grid item xs={12} mb={1}>
                        <Button disabled={props.readonly} variant="contained" size="small" startIcon={<AddIcon />} color="secondary" sx={{ mr: 1 }} aria-label={localization.newRecord} aria-controls="menu-add" aria-haspopup="true" onClick={() => handleCreate()}>{localization.newRecord}</Button>
                    </Grid>
                )}

                {/* Zoznam */}
                {
                    props.source.notes?.map((note, index) => {
                        return (
                            <Grid item xs={12} mt={1} key={index}>
                                <Alert
                                    variant="standard"
                                    severity="info"
                                    icon={<SpeakerNotesIcon />}
                                    onDoubleClick={() => handleCreate(index)}
                                    action={
                                        <>
                                            {props.readonly === false && (<>
                                                <IconButton aria-label={localization.edit} color="inherit" size="small" onClick={() => handleCreate(index)}>
                                                    <EditIcon fontSize="inherit" />
                                                </IconButton>
                                                <IconButton aria-label={localization.delete} color="inherit" size="small" onClick={() => handleDelete(index, localization.note)}>
                                                    <DeleteIcon fontSize="inherit" />
                                                </IconButton>
                                            </>)}
                                        </>
                                    }>
                                    {(note.name?.length ?? 0) > 0 && (
                                        <AlertTitle>
                                            <strong>{note.name}</strong>
                                        </AlertTitle>
                                    )}
                                    {dateFormat(new Date(note.date ?? new Date()), 'dd.MM.yyyy')}
                                    {(note.note?.length ?? 0) > 0 ? <><br />{note.note}</> : null}
                                </Alert>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    )
}

export default PonyCreateNotes;